import React from 'react'

export function DropDownDate(props) {
  let { title, setFilter } = { ...props }

  return (
    <div className=' box_ordering'>
      <div className="ttls_order">Ordenar:</div>
      <div className='mydropdown'>
        <a className="dropdown-toggle" href="#Dropdown" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{title}</a>

        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          <button className="dropdown-item " onClick={() => setFilter({ title: 'Los más recientes', value: 'desc' })} >Los más recientes</button>
          <button className="dropdown-item " onClick={() => setFilter({ title: 'Los más antiguos', value: 'asc' })} >Los más antiguos</button>
        </div>
      </div>
    </div>
  )
}

export function DropDownStatus(props) {
  let { title, setFilter } = { ...props }

  return (
    <div className=' box_ordering'>
      <div className="ttls_order">Mostrar:</div>
      <div className='mydropdown'>
        <a className="dropdown-toggle" href="#Dropdown" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{title}</a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          <button className="dropdown-item " onClick={() => setFilter({ title: 'Todos', value: '' })} >Todos</button>
          <button className="dropdown-item " onClick={() => setFilter({ title: 'Leidos', value: 'read' })} >Leidos</button>
          <button className="dropdown-item " onClick={() => setFilter({ title: 'No leidos', value: 'pending' })} >No leidos</button>
        </div>
      </div>
    </div>
  )
}