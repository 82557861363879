import { callApi, callApiFileStorage } from '../service'
import swal from "sweetalert"

export const productFilterService = {
    getAll,
    getById,
    create,
    edit,
    deleteProductFilter,
    getCatalogs
};


async function getAll() {
    return callApi('/BackOffice/RewardProductFilter', 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                return [];
            }
        });
}

async function getById(id) {
    return callApi('/BackOffice/RewardProductFilter/' + id, 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                return {};
            }
        });
}
async function create(param) {
    debugger;
    return callApi('/BackOffice/RewardProductFilter', 'POST', param)
        .then((result) => {
            if (result.responseCode === "00") {
                swal({ text: "Registro guardado exitosamente", icon: "success" })
                    .then(() => window.location.href = "/product-filter");
            } else {
                swal({ text: result.responseMessage, icon: "error" });
            }
        });
}

async function edit(param) {
    debugger;
    return callApi('/BackOffice/RewardProductFilter', 'PUT', param)
        .then((result) => {
            if (result.responseCode === "00") {
                swal({ text: "Registro guardado exitosamente", icon: "success" })
                    .then(() => window.location.href = "/product-filter");
            } else {
                swal({ text: result.responseMessage, icon: "error" });
            }
        });
}


async function getCatalogs() {
    return callApi('/BackOffice/RewardProductFilter/Catalogs', 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                return {};
            }
        });
}

async function deleteProductFilter(params) {
    return callApi('/BackOffice/RewardProductFilter?id=' + params, 'DELETE', null).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "El filtro desactivo correctamente", icon: "success" })
            return result.data;
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al desactivar el filtro", icon: "error" });
            return null;
        }
    });
}

