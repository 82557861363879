import React, { useEffect, useState } from 'react';
import { useSurveyUserProfileAnswer } from '../../services/surveyUserProfileAnswer.service';
import swal from "sweetalert";

export function AnswersSurvey(props) {
    const [userAnswer, setUserAnswer] = useState({});
    const [answerData, setAnswersData] = useState([]);
    const [SelectedAnswerId, setSelectedAnswerId] = useState("");
    const [SelectedValue, setSelectedValue] = useState("");

    useEffect(() => {
        getAnswers(props.question);
    }, [props.surveyQuestionId]);

    useEffect(() => {
        if (props.questionIndexSave === props.index) {
            handleSubmit();
        }
    }, [props.questionIndexSave]);

    async function getAnswers(question) {
        useSurveyUserProfileAnswer.getSurveysUserProfileAnswersByQuestionId(question.surveyQuestionId, props.surveyUserProfileId).then((result) => {
            if (result) {
                setAnswersData(result.answers);
                if (result.selectedAnswerId) {
                    setSelectedAnswerId(result.selectedAnswerId);
                    var ua = { SurveyUserProfileAnswerId: result.surveyUserProfileAnswerId, SurveyUserProfileId: props.surveyUserProfileId, SurveyQuestionId: props.surveyQuestionId, SelectedAnswerConfigId: result.selectedAnswerId, Value: result.answerValue };
                    setUserAnswer(ua);
                }
                if (result.answerValue)
                    setSelectedValue(result.answerValue);
            }
        });
    }
    const handleTextChange = (event, answer) => {
        event.persist();
        setSelectedAnswerId(answer.surveyAnswerConfigId);
        setSelectedValue(event.target.value);
    };
    const handleOptionChange = (event, answer) => {
        event.persist();
        setSelectedAnswerId(answer.surveyAnswerConfigId);
    };


    const handleSubmit = () => {
        if (userAnswer.SurveyUserProfileAnswerId) {
            var upd = { SurveyUserProfileAnswerId: userAnswer.SurveyUserProfileAnswerId, SurveyUserProfileId: props.surveyUserProfileId, SurveyQuestionId: userAnswer.SurveyQuestionId, SelectedAnswerConfigId: SelectedAnswerId, Value: SelectedValue };
            setUserAnswer(upd);
            useSurveyUserProfileAnswer.updateSurveyUserProfileAnswer(upd).then((result) => {
                if (result != null) {
                    if (result.isSurveyCompleted) {
                        swal({ text: "La encuesta se ha completado", icon: "success" }).then(() => window.location.reload());
                    } else {
                        setUserAnswer(result);
                        props.next();
                    }
                } else props.setQuestionIndexSave(-1)
            });
        } else {
            var ins = { SurveyUserProfileId: props.surveyUserProfileId, SurveyQuestionId: props.surveyQuestionId, SelectedAnswerConfigId: SelectedAnswerId, Value: SelectedValue };
            setUserAnswer(ins);
            useSurveyUserProfileAnswer.createSurveyUserProfileAnswer(ins).then((result) => {
                if (result != null) {
                    if (result.isSurveyCompleted) {
                        swal({ text: "La encuesta se ha completado", icon: "success" }).then(() => window.location.reload());
                    } else {
                        setUserAnswer(result);
                        props.next();
                    }
                } else props.setQuestionIndexSave(-1);
            });
        }
    };

    return (
        <div className="box_resp">
            {
                answerData.map(answer =>
                    <div key={answer.surveyAnswerConfigId}>
                        {
                            {
                                '1': <div className="form-group p-1">
                                    <input
                                        type="text"
                                        value={SelectedValue}
                                        onChange={(event) => handleTextChange(event, answer)}
                                        className="form-control"
                                    />
                                </div>,

                                '2': <div className="custom-control custom-radio ml-4 mb-3 text-left">
                                    <input
                                        type="radio"
                                        name={'Q' + props.surveyQuestionId}
                                        id={'Q' + answer.surveyAnswerConfigId}
                                        value={answer.surveyAnswerConfigId}
                                        checked={SelectedAnswerId === answer.surveyAnswerConfigId}
                                        onChange={(event) => handleOptionChange(event, answer)}
                                        className="custom-control-input"
                                    />

                                    <label className="custom-control-label" for={'Q' + answer.surveyAnswerConfigId}>{answer.answer}</label>
                                </div>,

                                '3': <div className="custom-control custom-radio ml-4 mb-3 text-left">
                                    <input
                                        type="radio"
                                        name={'Q' + props.surveyQuestionId}
                                        id={'Q' + answer.surveyAnswerConfigId}
                                        value={answer.surveyAnswerConfigId}
                                        checked={SelectedAnswerId === answer.surveyAnswerConfigId}
                                        onChange={(event) => handleOptionChange(event, answer)}
                                        className="custom-control-input"
                                    />
                                    <label className="custom-control-label" for={'Q' + answer.surveyAnswerConfigId}>{answer.answer}</label>

                                </div>,

                                '4': <div className="form-group p-1">
                                    <input
                                        type="text"
                                        value={SelectedValue}
                                        onChange={(event) => handleTextChange(event, answer)}
                                        className="form-control"
                                    />
                                </div>

                            }[props.question.surveyAnswerTypeId.toString()]
                        }
                    </div>
                )
            }
        </div>
    );
}