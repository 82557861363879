import React, { Fragment, useEffect, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Input } from "reactstrap";
import classnames from "classnames";
import { GeneralUpload } from "../../../components/Helpers/generalUpload";
import useInputForm from "../../../components/CustonHooks/FormHook";
import { useCompanyHoliday } from "../../../services/companyHoliday.service";
import ImgInfog from "../../../assets/imgs/imgInfografia.jpg";
import ReactPlayer from "react-player";
import moment from 'moment';

export function EditNotice(props) {
    const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
    const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

    const onSave = () => {
        let localfiles = [];
        if (image.file)
            localfiles.push(image.file);
        if (video)
            if (video.file)
                localfiles.push(video.file);

        if (inputs.CompanyHolidayId && inputs.CompanyHolidayId > 0)
            useCompanyHoliday
                .updateCompanyHoliday(
                    {
                        CompanyHolidayId: parseInt(inputs.CompanyHolidayId),
                        Description: inputs.Description,
                        Date: inputs.Date,
                        WorkingDay: inputs.WorkingDay,
                        EndDate: inputs.EndDate,
                        StartDate: inputs.StartDate,
                        TypeId: selectedTypeId,
                        IsVideo: inputs.IsVideo,
                        Iframe: inputs.Iframe
                    },
                    localfiles
                )
                .then((result) => {
                    if (props.onSave) props.onSave(result);
                });
        else
            useCompanyHoliday
                .createCompanyHoliday(
                    {
                        Description: inputs.Description,
                        Date: inputs.Date,
                        WorkingDay: inputs.WorkingDay,
                        EndDate: inputs.EndDate,
                        StartDate: inputs.StartDate,
                        TypeId: selectedTypeId,
                        IsVideo: inputs.IsVideo,
                        Iframe: inputs.Iframe
                    },
                    localfiles
                )
                .then((result) => {
                    if (props.onSave) props.onSave(result);
                });
    };
    const handleOnCancel = () => {
        if (props.onCancelEdit) props.onCancelEdit();
    };

    const handleOptionChange = (event, typeId) => {
        event.persist();
        setSelectedTypeId(typeId);
    };

    const [CompanyHoliday, setCompanyHoliday] = useState({
        CompanyHolidayId: "",
        Description: "",
        Date: "",
        WorkingDay: false,
        StartDate: "",
        EndDate: "",
        TypeId: 0,
        IsVideo: false,
        Iframe: ""
    });

    const { handleSubmit, handleInputChange, inputs, setInputs } = useInputForm(
        onSave,
        CompanyHoliday
    );

    const [image, setImage] = useState(null);
    const [video, setVideo] = useState(null);
    const [selectedTypeId, setSelectedTypeId] = useState(0);

    const handleImageUpload = (files) => {
        if (files.length > 0)
            setImage({ url: URL.createObjectURL(files[0]), file: files[0] });
        else setImage(null);
    };

    const handleVideoUpload = (files) => {
        if (files.length > 0)
            setVideo({ url: URL.createObjectURL(files[0]), file: files[0] });
        else setVideo(null);
    };

    useEffect(() => {
        let newP = {};
        if (props.currentCompanyHoliday) {
            newP = {
                CompanyHolidayId: props.currentCompanyHoliday.companyHolidayId.toString(),
                Description: props.currentCompanyHoliday.description,
                Date: formatDate(new Date(props.currentCompanyHoliday.date)),
                WorkingDay: props.currentCompanyHoliday.workingDay,
                EndDate: formatDate(new Date(props.currentCompanyHoliday.endDate)),
                StartDate: formatDate(new Date(props.currentCompanyHoliday.startDate)),
                TypeId: props.currentCompanyHoliday.typeId,
                IsVideo: props.currentCompanyHoliday.isVideo,
                Iframe: props.currentCompanyHoliday.iframe
            };
            setCompanyHoliday(newP);
            setSelectedTypeId(props.currentCompanyHoliday.typeId);
            setImage({ url: props.currentCompanyHoliday.imagePath, file: null });
            setVideo({ url: props.currentCompanyHoliday.videoPath, file: null });
            setShowVideo(props.currentCompanyHoliday.typeId == 1);
            clickVideoType(props.currentCompanyHoliday.iframe ? "1" : "0");
            toggle(props.currentCompanyHoliday.iframe ? "2" : "1");
        } else {
            setCompanyHoliday({
                CompanyHolidayId: "",
                Description: "",
                Date: "",
                WorkingDay: false,
                StartDate: "",
                EndDate: "",
                TypeId: 0,
                IsVideo: false,
                Iframe: ""
            });
            clickVideoType("0");
            toggle("1");
        }
    }, [props.currentCompanyHoliday]);

    const formatDate = (date) => {
        let datestring =
            date.getFullYear() +
            "-" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + date.getDate()).slice(-2);
        return datestring;
    };

    const [activeTab, setActiveTab] = useState("1");
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const [myModal, setMyModal] = useState("");
    const toggleMyModal = () => {
        setMyModal(myModal === "" ? "inShow" : "");
    };

    const [showVideo, setShowVideo] = useState(false);
    const toggleTrue = () => setShowVideo(true);
    const toggleFalse = () => setShowVideo(false);


    const [disabled, setDisabled] = useState(true);
    const [disabledIF, setDisabledIF] = useState(true);
    const clickVideoType = (value) => {
        if (value == "0") {
            setInputs({ ...inputs, Iframe: "" });
            setDisabled(false);
            setDisabledIF(true);
        } else {
            setVideo(null);
            setDisabled(true);
            setDisabledIF(false);
        }
    }

    return (
        <Fragment>
            <form onSubmit={handleSubmit}>
                <fieldset className="mt-4 mb-5">
                    <legend className="text fnt_medium">{inputs.CompanyHolidayId > 0 ? "Editar Aviso" : "Nuevo"}</legend>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label for="Description">Descripción</label>
                                <Input
                                    type="text"
                                    name="Description"
                                    placeholder="Aviso"
                                    onChange={handleInputChange}
                                    value={inputs.Description}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label for="StartDate">Fecha de evento</label>
                                <Input
                                    type="date"
                                    name="Date"
                                    onChange={handleInputChange}
                                    value={inputs.Date}
                                    min={moment(minDate).format('YYYY-MM-DD')}
                                    max={moment(maxDate).format('YYYY-MM-DD')}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label for="Tipo">Tipo</label>
                                <div>
                                    <input
                                        type="radio"
                                        name="TypeId"
                                        id="isAgenda"
                                        value="0"
                                        checked={selectedTypeId === 0}
                                        onChange={(event) => handleOptionChange(event, 0)}
                                        onClick={toggleFalse}
                                        required
                                    />{" "}
                                    <label className="cursor" htmlFor="isAgenda">
                                        Agenda
                                    </label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        name="TypeId"
                                        value="1"
                                        checked={selectedTypeId === 1}
                                        onChange={(event) => handleOptionChange(event, 1)}
                                        onClick={toggleTrue}
                                        required
                                    />{" "}Aviso
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label for="StartDate">Desde</label>
                                <Input
                                    type="date"
                                    name="StartDate"
                                    onChange={handleInputChange}
                                    value={inputs.StartDate}
                                    min={moment(minDate).format('YYYY-MM-DD')}
                                    max={moment(maxDate).format('YYYY-MM-DD')}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label for="EndDate">Hasta</label>
                                <Input
                                    type="date"
                                    name="EndDate"
                                    onChange={handleInputChange}
                                    value={inputs.EndDate}
                                    min={moment(inputs.StartDate).format('YYYY-MM-DD')}
                                    max={moment(maxDate).format('YYYY-MM-DD')}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <GeneralUpload
                                TitleLabel={"Subir imagen"}
                                handleOnChangeFiles={handleImageUpload}
                                accept="image/*"
                                required={inputs.CompanyHolidayId == 0}
                            />
                        </div>
                        {image ? (
                            <div className="col-md-4">
                                <img src={image.url} style={{ width: "175px" }} />
                            </div>
                        ) : null}
                    </div>
                    <div className={`row brox_for_video ${showVideo ? "is_show" : ""}`}>
                        <div className="col-sm-6 col-lg-2 mb-2">
                            <label for="IsVideo">Tipo de aviso</label>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="IsVideo" name="IsVideo" onChange={handleInputChange} checked={inputs.IsVideo} />
                                <label className="custom-control-label" for="IsVideo">Es un video</label>
                            </div>
                        </div>
                        {inputs.IsVideo == true &&
                            <div className="col-md-12 mt-4 mb-4 box_tabs_avisos">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "1" })}
                                            onClick={() => {
                                                toggle("1");
                                            }}
                                        >
                                            Videos en mi PC
                                </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === "2" })}
                                            onClick={() => {
                                                toggle("2");
                                            }}
                                        >
                                            Videos externos
                                    </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <div className="card_tabs pt-3">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <div>
                                                            <input
                                                                type="radio"
                                                                name="videoActive"
                                                                id="videoActive1"
                                                                onClick={() => clickVideoType("0")}
                                                                defaultChecked={!inputs.Iframe}
                                                            />{" "}
                                                            <label className="cursor" htmlFor="videoActive1">
                                                                Activo
                                                        </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <GeneralUpload
                                                        TitleLabel={"Subir video local"}
                                                        handleOnChangeFiles={handleVideoUpload}
                                                        accept="video/mp4,video/x-m4v,video/*"
                                                        disabled={disabled}
                                                        required={inputs.CompanyHolidayId == 0}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <ReactPlayer
                                                        url={video && video.url}
                                                        width="100%"
                                                        height="100%"
                                                        controls={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <div className="card_tabs pt-3">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <div>
                                                            <input
                                                                type="radio"
                                                                name="videoActive"
                                                                id="videoActive2"
                                                                onClick={() => clickVideoType("1")}
                                                                defaultChecked={inputs.Iframe}
                                                            />{" "}
                                                            <label className="cursor" htmlFor="videoActive2">
                                                                Activo
                                                        </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div class="form-group mb-1">
                                                        <label htmlFor="exampleFormControlTextarea1">
                                                            Iframe para video <i>(Youtube)</i>
                                                        </label>
                                                        <textarea
                                                            class="form-control textarea-box"
                                                            id="exampleFormControlTextarea1"
                                                            rows="3"
                                                            name="Iframe"
                                                            onChange={handleInputChange}
                                                            value={inputs.Iframe}
                                                            disabled={disabledIF}
                                                            required
                                                        ></textarea>
                                                    </div>
                                                    <div className="box_btn_modal">
                                                        <span className="c_primary cursor" onClick={toggleMyModal}>
                                                            ¿Cómo crear un iframe en youtube?
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div
                                                        dangerouslySetInnerHTML={{ __html: inputs.Iframe }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </div>
                        }
                    </div>
                    <hr />
                    <div>
                        <button
                            className="btn secundary minimum ml-1 mr-1"
                            type="button"
                            onClick={handleOnCancel}
                        >
                            Regresar
                        </button>
                        <input
                            className="btn primary minimum ml-1 mr-1"
                            type="submit"
                            value="Guardar"
                        />
                    </div>
                </fieldset>
            </form>
            <section className={`wrapper__modal_infog ${myModal}`}>
                <span className="btnInCLose" onClick={toggleMyModal}>
                    CERRAR
                </span>
                <div className="modal_infog">
                    <img className="imginmodal" src={ImgInfog} alt="Infografía Kuimby" />
                </div>
            </section>
        </Fragment>
    );
}
