const initState = {
    userSettings: {
        companyId: 0,
        roleName: ""
    },
    countrySetting: {
        cultureCode: "es-MX",
        isoCurrency: "MXN",
        currency: "$"
    },
    cartQuantity: 0,
    blocking: false,
    calendarStrings: {
        lastDay: '[ayer]',
        sameDay: '[hoy]',
        nextDay: '[mañana]',
        lastWeek: '[el] dddd [anterior]',
        nextWeek: '[el] dddd',
        sameElse: '[el] DD [de] MMMM [de] yyyy'
    },
    accountSettings: {
        activateRegistration: false,
        productSubscription: 0
    }
};


export const reducers = (state = initState, action) => {
    switch (action.type) {
        case "Set_CountrySetting":
            return {
                ...state,
                countrySetting: action.payload
            };
        case "Set_CartQuantity":
            return {
                ...state,
                cartQuantity: localStorage["cartQuantityKuimby"]
            };
        case "Toggle_Blocking":
            return {
                ...state,
                blocking: action.payload
            };
        case "Set_UserSettings":
            return {
                ...state,
                userSettings: action.payload
            };
        case "Set_AccountSettings":
            return {
                ...state,
                accountSettings: action.payload
            };
        default:
            return state;
    }
}