import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { courseService } from '../../services/course.service';
import { useDispatch } from 'react-redux';

const SearchCourse = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const [dataGrid, setDataGrid] = useState([]);

    const handleDelete = (event, data) => {
        courseService.deleteCourse(data).then((result) => { getCourse(); });
        event.stopPropagation();
    };

    async function getCourse() {
        courseService.getCourse()
            .then((result) => {
                if (result) {
                    setDataGrid(result);
                }
            });
    }

    useEffect(() => {
        getCourse();
    }, []);

    const refreshDataGrid = () => {
        getCourse();
    }

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));

    return (
        <div className="table-responsive card shadows p-3">
            <table className="wrapper_table table table-hover table-sm">
                <thead>
                    <tr>
                        <th className="text fnt_medium">Tipo de capacitación</th>
                        <th className="text fnt_medium min_width">Nombre</th>
                        <th className="text fnt_medium min_width">Descripción</th>
                        <th className="text fnt_medium">Estatus</th>
                        <th className="text fnt_medium">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {dataGrid.map(item => (
                        <tr key={item.courseId} >
                            <td>{item.courseMediaTypeName}</td>
                            <td><span className="link_data" onClick={() => props.handleRowSelect(item)}>{item.name}</span></td>
                            <td>{item.description}</td>
                            <td>{item.active ? 'Activo' : 'Inactivo'}</td>
                            {item.active ?
                                <td className="text-center">
                                    <span className="icon_tables" onClick={(event) => handleDelete(event, item)}>
                                        <i className="far fa-trash-alt"></i>
                                    </span>
                                </td> : null}
                        </tr>
                    ))}

                </tbody>
            </table>
        </div>
    );

});

export default SearchCourse;
