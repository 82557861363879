import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import { useAcknowledgment } from '../../services/acknowledgment.service'

export function SearchBoard(props) {
	const [acknowledgmentTypes, setAcknowledgmentTypes] = useState([]);
	const [isActive, setIsActive] = useState(1);

	useEffect(() => {
		useAcknowledgment.getAcknowledgments()
			.then((data) => setAcknowledgmentTypes(data))
	}, [])

	const showAcknowledgmentType = (acknowledgmentType) => {
		let show = false
		switch (isActive) {
			case 1:
				show = true
				break;
			case 2:
				show = (Date.now() - Date.parse(acknowledgmentType.validTo) <= 86400000)
					&& Date.parse(acknowledgmentType.validFrom) < Date.now()
					&& acknowledgmentType.active
				break
			case 3:
				show = (Date.parse(acknowledgmentType.validTo) < Date.now()) ||
					(Date.parse(acknowledgmentType.validFrom) > Date.now()) ||
					(!acknowledgmentType.active)
				break
		}
		return show
	}

	return (
		<div className="table-responsive card shadows p-3">
			<div className="btn-group" role="group" aria-label="Basic example">
				<button className={"btns " + (isActive === 1 ? "active" : "")} onClick={() => setIsActive(1)}>Todos</button>
				<button className={"btns " + (isActive === 2 ? "active" : "")} onClick={() => setIsActive(2)}>Activos</button>
				<button className={"btns " + (isActive === 3 ? "active" : "")} onClick={() => setIsActive(3)}>Inactivos</button>
			</div>
			<table className="wrapper_table table table-hover table-sm">
				<thead>
					<tr>
						<th className="text fnt_medium">ID Categoría</th>
						<th className="text fnt_medium">Titulo</th>
						<th className="text fnt_medium">Descripción</th>
						<th className="text fnt_medium">Status</th>
						<th className="text fnt_medium">Desde</th>
						<th className="text fnt_medium">Hasta</th>
						<th className="text fnt_medium">Acciones</th>
					</tr>
				</thead>
				<tbody>
					{acknowledgmentTypes.map(acknowledgmentType => (
						<Fragment>
							{showAcknowledgmentType(acknowledgmentType) &&
								<tr key={acknowledgmentType.acknowledgmentTypeId}>
									<td>{acknowledgmentType.acknowledgmentTypeId}</td>
									<td>{acknowledgmentType.title}</td>
									<td>{acknowledgmentType.descritption}</td>
									<td>{acknowledgmentType.active ? 'Active' : 'Inactive'}</td>
									<td><Moment locale="es" format="DD/MM/YYYY">{acknowledgmentType.validFrom}</Moment></td>
									<td><Moment locale="es" format="DD/MM/YYYY">{acknowledgmentType.validTo}</Moment></td>
									<td>
										<Link to={'/details-category/' + acknowledgmentType.acknowledgmentTypeId}>
											<span className="icon_tables">
												<i className="fas fa-pen"></i>
											</span>
										</Link>
									</td>
								</tr>
							}
						</Fragment>
					))}
				</tbody>
			</table>
		</div>
	);
}