import React, { useState } from 'react';
import { SearchReward } from './searchReward';

import './rewardAdmin.scss'
import { Link } from 'react-router-dom';

export function AdminReward() {
    const [refresh, setRefresh] = useState(0);

    return (
        <section className="wrapper_reward_admin padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt no_reverse mb-0">
                    <div>
                        <h2 className="mb-0 text fnt_medium">Productos</h2>
                        <h6 className="mb-0 text-muted">Operaciones</h6>
                    </div>
                    <div className="box_actions">
                        <Link className="btn primary" to={"/details-reward/0"}><i className="fas fa-plus"></i> Agregar recompensa</Link>
                    </div>
                </div>
            </div>
            <div>
                <SearchReward refresh={refresh} />
            </div>

        </section>
    );
}