import React, { useState } from 'react';
import { ListNotice } from './listNotice'
import { EditNotice } from './editNotice'

export function Notice() {
    const [showEdit, setShowEdit] = useState(false);
    const [selectedCompanyHoliday, setSelectedCompanyHoliday] = useState();
    const [refresh, setRefresh] = useState(0);

    const handleOnCompanyHolidaySelected = (CompanyHoliday) => {
        setShowEdit(true);
        setSelectedCompanyHoliday(CompanyHoliday);
    };
    const handleOnNewCompanyHoliday = () => {
        setSelectedCompanyHoliday(null);
        setShowEdit(true);
    };
    const handleOnCancelEdit = () => {
        setSelectedCompanyHoliday(null);
        setShowEdit(false);
    }
    const handleOnSaveEdit = (result) => {
        setRefresh(prev => prev + 1);
        if (result)
            setShowEdit(false);
    }
    return (
        <div>
            {showEdit ? <EditNotice currentCompanyHoliday={selectedCompanyHoliday} onCancelEdit={handleOnCancelEdit} onSave={handleOnSaveEdit} /> : null}
            {!showEdit ? <ListNotice onNewClicked={handleOnNewCompanyHoliday} onSelectCompanyHoliday={handleOnCompanyHolidaySelected} refresh={refresh} /> : null}
        </div>
    )
} 