import { callApi, callApiFileStorage } from './service'
import swal from "sweetalert"

export const messagePrivilegeService = {
    deleteMessagePrivileges,
    addMessagePrivileges,
    getAllUserPrivileges,
    getUserPrivilege
}

async function getAllUserPrivileges(){
    return callApi('/backoffice/MessageSender', 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return null
        }
    });
}

async function getUserPrivilege(){
    return callApi('/backoffice/MessageSender/user', 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return null
        }
    });
}

async function deleteMessagePrivileges(id){
    return callApi('/backoffice/MessageSender/' + id, 'DELETE').then((result) => {
        if (result.responseCode === '00')
            return result.data
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return null
        }
    });
}

async function addMessagePrivileges(params){

    return callApi('/backoffice/MessageSender', 'POST', params).then((result) => {
        if (result.responseCode === '00')
            return result.data
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return null
        }
    });
}