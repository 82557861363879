import React, { useState } from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap';
import Moment from 'react-moment'
import 'moment/locale/es';
import { useSelector } from 'react-redux';

export function ListSurveys({ surveys, onSelectSurvey }) {
    const [surveyActive, setSurveyActive] = useState(0);

    const calendarStrings = useSelector(state => state.calendarStrings);

    return (
        <ListGroup>
            {surveys.map(survey =>
                <ListGroupItem className={`${surveyActive == survey.surveyId ? 'active' : ''}`} key={survey.surveyUserProfileId} onClick={() => { onSelectSurvey(survey); setSurveyActive(survey.surveyId); }}>
                    <h6 className="text fnt_medium mb-1">{survey.title}</h6>
                    <h6 className="text-muted mb-0">{survey.questionCount} reactivos</h6>
                    <h6 className="text-muted mb-0">Vence <Moment calendar={calendarStrings}>{survey.endDate}</Moment></h6>
                </ListGroupItem>
            )}
        </ListGroup>
    )
}