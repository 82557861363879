import React, { useState } from 'react';
import { EditCompany } from './editCompany'

export function DetailsCompany(props) {

    return (
        <div className="wrapper_details_company padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt mb-0">
                    <div>
                        <h2 className="mb-0 text fnt_medium">Empresas</h2>
                        <h6 className="mb-0 text-muted">{props.match.params.id != 0 ? "Editar " : "Nueva "}empresa</h6>
                    </div>
                </div>
            </div>

            <EditCompany companyId={props.match.params.id} />
        </div>
    );
}   
