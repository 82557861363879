import React, { useState } from 'react';
import { EditRewardCategory } from './editRewardCategory'

export function DetailsRewardCategory(props) {

    return (
        <div className="wrapper_details_reward padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt mb-0">
                    <div>
                        <h2 className="mb-0 text fnt_medium">Recompensas - Categorías</h2>
                        <h6 className="mb-0 text-muted">{props.match.params.id != 0 ? "Editar categoría" : "Nueva categoría"}</h6>
                    </div>
                </div>
            </div>

            <EditRewardCategory rewardCategoryId={props.match.params.id} />
        </div>
    );
}   
