import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Table } from "reactstrap";
import { branchOfficeService } from '../../../services/branchOffice.service';


const SearchBranchOffice = forwardRef((props, ref) => {

    const [dataGrid, setDataGrid] = useState([]);
    const handleDelete = (event, data) => {
        branchOfficeService.deleteBranchOffice(data).then((result) => { getAll(); });
        event.stopPropagation();
    };

    async function getAll() {
        branchOfficeService.getAll()
            .then((result) => {
                if (result) {
                    setDataGrid(result);
                }
            });
    }

    useEffect(() => {
        getAll();
    }, []);

    const refreshDataGrid = () => {
        getAll();
    }

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid
    }));

    return (

        <div className="table-responsive">
            <table className="wrapper_table table table-sm table-hover">
                <thead>
                    <tr>
                        <th className="text fnt_medium">Sucursal</th>
                        <th className="text fnt_medium">Estatus</th>
                        <th className="text fnt_medium">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {dataGrid.map(item => (
                        <tr key={item.branchOfficeId} >
                            <td>{item.branchOfficeName}</td>
                            <td>{item.active ? 'Activo' : 'Inactivo'}</td>
                            <td>
                                {item.active ?
                                    <span>
                                        <span className="icon_tables" onClick={() => props.handleRowSelect(item)}>
                                            <i className="fas fa-pen"></i>
                                        </span>
                                        <span className="icon_tables" onClick={(event) => handleDelete(event, item)}>
                                            <i className="far fa-trash-alt"></i>
                                        </span>
                                    </span>
                                    : null}
                            </td>
                        </tr>
                    ))}

                </tbody>

            </table >
        </div>
    );
});

export default SearchBranchOffice;
