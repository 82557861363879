import React, { useState } from 'react';
import { EditJobRole } from './editJobRole';
import { SearchJobRole } from './searchJobRoles';

export function AdminJobRoles() {
	const [showEdit, setShowEdit] = useState(false);
	const [selectedJobRole, setSelectedJobRole] = useState();
	const [refresh, setRefresh] = useState(0);

	const handleOnSelected = (jobRole) => {
		setShowEdit(true);
		setSelectedJobRole({ JobRoleId: jobRole.jobRoleId, Description: jobRole.description, Name: jobRole.name });
	};
	const handleOnNew = () => {
		setSelectedJobRole(null);
		setShowEdit(true);
	};
	const handleOnCancelEdit = () => {
		setSelectedJobRole(null);
		setShowEdit(false);
	}
	const handleOnSaveEdit = () => {
		setRefresh(prev => prev + 1);
		setShowEdit(false);
	}
	return (
		<div>
			<div className="mb-4 mt-4 divtop ">
				<div className="box_ttl_opt no_reverse">
					<h3 className="mb-0">Lista de categorías</h3>
					<div className="box_actions ">
						<button type="button" className="btn secundary mr-2" onClick={handleOnNew}><i className="fas fa-plus"></i> Agregar categoría</button>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					{showEdit ?
						<EditJobRole currentJobRole={selectedJobRole} onCancelEdit={handleOnCancelEdit} onSave={handleOnSaveEdit} />
						: null}
				</div>
			</div>

			<SearchJobRole onSelectJobRole={handleOnSelected} refresh={refresh} />
		
		</div>
	);
}