import { callApi, callApiFileStorage } from './service'
import swal from "sweetalert"

export const userService = {
    login,
    logout,
    forgotPassword,
    resetPassword,
    editProfile,
    validateEmailAccount,
    register,
    verifyAccount
};

/**
 * Login
 */
async function login(params) {
    return callApi('/Auth/Login', 'POST', params)
        .then((result) => {
            if (result.responseCode === "00") {
                localStorage.setItem('roleName', result.data.roleName);
                localStorage.setItem('kuimby_token', result.data.token);
                localStorage.setItem('kuimby_refresh_token', result.data.refreshToken);
                localStorage.setItem('userId', result.data.userId);
            }
            return result;
        });
}

/**
 * Logout
 */
async function logout() {
    localStorage.removeItem('kuimby_token');
    localStorage.removeItem('kuimby_refresh_token');
    localStorage.removeItem('roleName');
}

/*
 Send email to recovery password
 */
async function forgotPassword(params) {
    return callApi('/Auth/ForgotPassword', 'POST', params)
        .then((result) => {
            if (result.responseCode === "00") {
                swal({ text: "Se ha enviado un link a tu correo para reestablecer tu contraseña", icon: "success" }).then(() => window.location.href = "/login")
            } else {
                swal({ text: result.responseMessage, icon: "error" });
            }
        })
}

async function validateEmailAccount(params) {
    return callApi('/Auth/ValidateEmailAccount', 'POST', params)
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                swal({ text: result.responseMessage, icon: "error" });
            }
        })
}
/*
 Set the new user password
 */
async function resetPassword(params) {
    return callApi('/Auth/ResetPassword', 'POST', params)
        .then((result) => {
            if (result.responseCode === "00") {
                swal({ text: "Tu contraseña ha sido restablecida.", icon: "success" }).then(() => window.location.href = "/login")
            } else {
                swal({ text: result.responseMessage, icon: "error" });
            }
        })
}


async function editProfile(data, companyId) {
    const formData = new FormData();
    formData.append("CompanyId", companyId);
    formData.append("ProfileImage", data.ProfileImage != null ? data.ProfileImage[0] : null);

    if (data.nickname != null && data.nickname != 'undefined') {
        if (data.nickname.trim() == '') {
            formData.append("nickname", "a5cdc20f-c8fe-48df-be43-fc56f43a198a");

        }
        else {
            formData.append("nickname", data.nickname);
        }
    }
    if (data.additionalEmail != null && data.additionalEmail != 'undefined') {
        if (data.additionalEmail.trim() == '') {
            formData.append("additionalEmail", "a5cdc20f-c8fe-48df-be43-fc56f43a198a");

        }
        else {
            formData.append("additionalEmail", data.additionalEmail);
        }
    }

    if (data.Notification != null) {
        formData.append("Notification", data.Notification ? "1" : "0");
    }


    if (data.Preferences != null) formData.append("Preferences", data.Preferences);
    if (data.Mobile != null) formData.append("Mobile", data.Mobile);
    return callApiFileStorage('/Account/EditProfile', 'POST', formData, false)
        .then((result) => {
            if (result.responseCode === "00") {
                return swal({ text: "El perfil se actualizo correctamente", icon: "success" }).then((value) => window.location.href = "/profile");
            } else {
                swal({ text: result.responseMessage, icon: "error" });
                return false;
            }
        });
}

async function register(model) {
    return callApi('/Auth/Register', 'POST', model)
        .then((result) => {
            if (result.responseCode === "00") {
                swal({ text: result.data, icon: "success" }).then(() => window.location.href = "/login")
            } else {
                swal({ text: result.responseMessage, icon: "error" });
            }
        });
}

async function verifyAccount(params) {
    return callApi('/Auth/VerifyAccount', 'POST', params)
        .then((result) => {
            return result;
        })
}