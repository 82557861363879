import { callApi } from './service'

export const useSurveyAnswerType = {
    createSurveyAnswerType,
    updateSurveyAnswerType,
    deleteSurveyAnswerType,
    getSurveyAnswerTypes
};
async function createSurveyAnswerType(params) {
    return callApi('/backoffice/SurveyAnswerType', 'POST', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function getSurveyAnswerTypes() {
    return callApi('/backoffice/SurveyAnswerType', 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function updateSurveyAnswerType(params) {
    return callApi('/backoffice/SurveyAnswerType', 'PUT', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function deleteSurveyAnswerType(params) {
    return callApi('/backoffice/SurveyAnswerType', 'DELETE', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}