import React, { Fragment, useEffect, useState } from "react";
import { courseViewService } from "../../services/courseView.service";
import Moment from "react-moment";
import "moment/locale/es";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";
import ReactPlayer from "react-player";

export function BoxListCourse({ dataGrid, setCompleteCourse }) {
  const calendarStrings = useSelector((state) => state.calendarStrings);
  const [course, setCourse] = useState({ type: 0, value: "", fileType: "" });

  const handleOnFiles = (file) => {
    courseViewService
      .getFileById(file.entityId, file.entityTypeId, file.sequence)
      .then((result) => {
        if (result) {
          const fileType = result.fileName.split(".")[1];
          if (fileType == "pdf")
            toggleCourse(
              1,
              `data:application/${fileType};base64,${result.file}`,
              fileType
            );
          else if (fileType == "mp4")
            handleVideoUpload(
              `data:application/${fileType};base64,${result.file}`,
              fileType
            );
          else downloadFile(file.fileName, result.file);
        }
      });
  };

  const handleComplete = (event, course) => {
    setCompleteCourse(course);
    event.preventDefault();
  };

  const handleUrl = (event, course, type) => {
    setCompleteCourse(course);
    toggleCourse(type, course.urlMedia);
    event.preventDefault();
  };

  const toggleCourse = (type, value, fileType) => {
    setCourse({ type, value, fileType });
    toggle();
  };

  const downloadFile = (fileName, fileb64) => {
    const linkSource = `data:application/octet-stream;base64,${fileb64}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleVideoUpload = (files, fileType) => {
    if (files.length > 0)
      fetch(files)
        .then((res) => res.blob())
        .then((blob) => {
          toggleCourse(1, URL.createObjectURL(blob), fileType);
        });
  };

  return (
    <Fragment>
      <div className="card shadows content_card_audience">
        <h4 className="text-muted text fnt_medium">Cursos disponibles</h4>
        <div className="box_list_audience">
          {dataGrid.map((item) => (
            <Fragment>
              {
                {
                  1: (
                    <Fragment>
                      {item.files != null && item.files.length > 0 && (
                        <a
                          className="item_audience"
                          onClick={(event) => handleComplete(event, item.data)}
                        >
                          <div className="box_info_audience">
                            <div className="box_icon">
                              <span>
                                <ion-icon name="document-outline"></ion-icon>
                              </span>
                            </div>
                          </div>

                          <div className="w-100">
                            <h6 className="text fnt_medium mb-0">
                              {item.data.name}
                            </h6>
                            {item.files.map((itemFile) => (
                              <a
                                className=""
                                onClick={() => handleOnFiles(itemFile)}
                              >
                                <p className="text-muted mb-0 c_primary">
                                  {itemFile.fileName}
                                </p>
                              </a>
                            ))}

                            <div className="text-muted mb-0">
                              <Moment calendar={calendarStrings}>
                                {item.data.endDate}
                              </Moment>
                            </div>
                          </div>
                        </a>
                      )}
                    </Fragment>
                  ),
                  2: (
                    <a
                      className="item_audience"
                      key={item.data.courseId}
                      href={item.data.urlMedia}
                      target="_blank"
                      // onClick={(event) =>
                      //   handleUrl(event, item.data, item.data.courseMediaTypeId)
                      // }
                    >
                      <div className="box_info_audience">
                        <div className="box_icon">
                          <span>
                            <ion-icon name="unlink-outline"></ion-icon>
                          </span>
                        </div>
                      </div>
                      <div className="w-100">
                        <h6 className="text fnt_medium mb-0">
                          {item.data.name}
                        </h6>
                        <p className="text-muted mb-0 c_primary">
                          {item.data.description}
                        </p>
                        <div className="text-muted mb-0">
                          <Moment calendar={calendarStrings}>
                            {item.data && item.data.endDate}
                          </Moment>
                        </div>
                      </div>
                    </a>
                  ),
                  3: (
                    <a
                      className="item_audience"
                      key={item.data.courseId}
                      href={item.data.urlMedia}
                      onClick={(event) =>
                        handleUrl(event, item.data, item.data.courseMediaTypeId)
                      }
                    >
                      <div className="box_info_audience">
                        <div className="box_icon">
                          <span>
                            <ion-icon name="play-circle-outline"></ion-icon>
                          </span>
                        </div>
                      </div>
                      <div className="w-100">
                        <h6 className="text fnt_medium mb-0">
                          {item.data.name}
                        </h6>
                        <p className="text-muted mb-0 c_primary">
                          {item.data.description}
                        </p>
                        <div className="text-muted mb-0">
                          <Moment calendar={calendarStrings}>
                            {item.data && item.data.endDate}
                          </Moment>
                        </div>
                      </div>
                    </a>
                  ),
                }[item.data.courseMediaTypeId]
              }
            </Fragment>
          ))}
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle} className="wrapper_modal_cap">
        {
          {
            1: (
              <div>
                <div className="toolbar_opt_modal">
                  <span className="btns_modal text fnt_bold" onClick={toggle}>
                    <i className="far fa-window-close"></i> <span>CERRAR</span>
                  </span>
                </div>

                {
                  {
                    pdf: <iframe src={course.value}></iframe>,
                    mp4: (
                      <ReactPlayer
                        url={course.value}
                        width="100%"
                        height="100%"
                        controls={true}
                      />
                    ),
                  }[course.fileType]
                }
              </div>
            ),
            // 2: (
            //   <div>
            //     <div className="toolbar_opt_modal">
            //       <a
            //         target="_blank"
            //         href={course.value}
            //         className="btns_modal text fnt_bold"
            //       >
            //         <i className="fas fa-external-link-alt"></i>{" "}
            //         <span>Abrir en otra pestaña</span>
            //       </a>
            //       <span className="btns_modal text fnt_bold" onClick={toggle}>
            //         <i className="far fa-window-close"></i> <span>CERRAR</span>
            //       </span>
            //     </div>

            //     <iframe src={course.value}></iframe>
            //   </div>
            // ),
            3: (
              <div>
                <div className="toolbar_opt_modal">
                  <span className="btns_modal text fnt_bold" onClick={toggle}>
                    <i className="far fa-window-close"></i> <span>CERRAR</span>
                  </span>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: course.value }}
                  style={{ display: "contents" }}
                />
              </div>
            ),
          }[course.type]
        }
      </Modal>
    </Fragment>
  );
}
