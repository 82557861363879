import React, { useEffect } from 'react';
import {
    Route,
    Switch,
    Redirect,
    useLocation
} from 'react-router-dom'
import {
    LayoutFull,
    LayoutLess
} from './components/layouts'

import * as Pages from './pages'
import * as Admin from './backoffice'
import * as Control from './control'
import ReactGA from 'react-ga';

export function App(props) {
    const location = useLocation();

    useEffect(() => {
        //Google Analytics()
        ReactGA.initialize(process.env.REACT_APP_TRACKING_ID_GA);
        ReactGA.plugin.require('displayfeatures');
        ReactGA.pageview(location.pathname);
    }, []);

    useEffect(() => {
        ReactGA.pageview(location.pathname)
    }, [location.pathname]);

    return (

        <Switch>
            <Route exact path='/login' render={x => (<LayoutLess children={<Pages.Login {...x} />} />)} />
            <Route exact path='/register' render={x => (<LayoutLess children={<Pages.Register {...x} />} />)} />
            <Route exact path='/verify-account' render={x => (<LayoutLess children={<Pages.VerifyAccount {...x} />} />)} />
            <Route exact path='/membership' render={x => (<LayoutLess children={<Pages.Membership {...x} />} />)} />
            <Route exact path='/order-details/:id' render={x => (<LayoutLess children={<Pages.MembershipPayment {...x} />} />)} />
            <Route exact path='/canceled' render={x => (<LayoutLess children={<Pages.MembershipPayment {...x} />} />)} />
            <Route exact path='/reset-password' render={x => (<LayoutLess children={<Pages.ResetPassword {...x} />} />)} />


            <Route exact path='/home' render={x => (<LayoutFull children={<Pages.Home />} />)} />
            <Route exact path='/surveys' render={x => (<LayoutFull children={<Pages.Surveys />} />)} />
            <Route exact path='/board' render={x => (<LayoutFull children={<Pages.Board />} />)} />
            <Route exact path='/messages' render={x => (<LayoutFull children={<Pages.Messages />} />)} />
            <Route exact path='/messages/:id' render={x => (<LayoutFull children={< Pages.ReadMessage />} />)} />
            <Route exact path='/discount' render={x => (<LayoutFull children={<Pages.Discount />} />)} />
            <Route exact path="/provider/:id" render={x => (<LayoutFull children={<Pages.Provider {...x} />} />)} />
            <Route exact path='/oxxopay' render={x => (<LayoutFull children={<Pages.OxxoPay />} />)} />
            <Route exact path='/shopping-history' render={x => (<LayoutFull children={<Pages.ShoppingHistory />} />)} />
            <Route exact path='/rewards' render={x => (<LayoutFull children={<Pages.Rewards />} />)} />
            <Route exact path='/redemption-history' render={x => (<LayoutFull children={<Pages.RedemptionHistory />} />)} />
            <Route exact path='/account-status' render={x => (<LayoutFull children={<Pages.AccountStatus />} />)} />
            <Route exact path='/profile' render={x => (<LayoutFull children={<Pages.Profile />} />)} />
            <Route exact path='/our-company' render={x => (<LayoutFull children={<Pages.CompanyPostView />} />)} />
            <Route exact path='/help' render={x => (<LayoutFull children={<Pages.Help />} />)} />
            <Route exact path='/audience' render={x => (<LayoutFull children={<Pages.Audience />} />)} />
            <Route exact path='/ecommerce' render={x => (<LayoutFull children={<Pages.Ecommerce />} />)} />



            {/* == BACKOFFICE == */}
            <Route exact path='/users' render={x => (<LayoutFull children={<Admin.Users />} />)} />
            <Route exact path='/users-details/:id' render={x => (<LayoutFull children={<Admin.UserDetails {...x} />} />)} />
            <Route exact path='/bulk-load' render={x => (<LayoutFull children={<Admin.BulkLoad />} />)} />

            <Route exact path='/AdminSurvey' render={x => (<LayoutFull children={<Admin.AdminSurvey />} />)} />
            <Route exact path='/AdminAnswerTypes' render={x => (<LayoutFull children={<Admin.AdminAnswerTypes />} />)} />
            <Route exact path='/details-survey/:id' render={x => (<LayoutFull children={<Admin.DetailsSurvey  {...x} />} />)} />

            <Route exact path='/AdminBoard' render={x => (<LayoutFull children={<Admin.AdminBoard />} />)} />
            <Route exact path='/details-category' render={x => (<LayoutFull children={<Admin.DetailsCategory />} />)} />
            <Route exact path='/details-category/:id' render={x => (<LayoutFull children={<Admin.DetailsCategory />} />)} />

            <Route exact path='/control' render={x => (<LayoutFull children={<Admin.Control />} />)} />
            <Route exact path='/CompanyEventType' render={x => (<LayoutFull children={<Pages.AdminCompanyEventType />} />)} />
            <Route exact path='/CompanyPost' render={x => (<LayoutFull children={<Admin.CompanyPost />} />)} />
            <Route exact path='/AdminGallery' render={x => (<LayoutFull children={<Pages.AdminGallery />} />)} />
            <Route exact path='/CompanyGallery' render={x => (<LayoutFull children={<Pages.CompanyGallery />} />)} />
            <Route exact path='/Notice' render={x => (<LayoutFull children={<Admin.Notice />} />)} />
            <Route exact path='/admin-diary' render={x => (<LayoutFull children={<Admin.AdminDiary />} />)} />
            <Route exact path='/diary' render={x => (<LayoutFull children={<Pages.Diary />} />)} />
            <Route exact path='/product-filter' render={x => (<LayoutFull children={<Admin.AdminProductFilter {...x} />} />)} />
            <Route exact path='/detail-product-filter/:id' render={x => (<LayoutFull children={<Admin.DetailsProductFilter {...x} />} />)} />

            {/* == OPERACIONES == */}
            <Route exact path='/homeOps/:tap' render={x => (<LayoutFull children={<Control.ControlReward {...x} />} />)} />
            <Route exact path='/controlreward/:tap' render={x => (<LayoutFull children={<Control.ControlReward {...x} />} />)} />
            <Route exact path='/details-reward/:id' render={x => (<LayoutFull children={<Control.DetailsReward  {...x} />} />)} />
            <Route exact path='/load-coupons/:id' render={x => (<LayoutFull children={<Control.BulkLoadReward  {...x} />} />)} />
            <Route exact path='/details-rewardCategory/:id' render={x => (<LayoutFull children={<Control.DetailsRewardCategory  {...x} />} />)} />
            <Route exact path='/details-rewardProvider/:id' render={x => (<LayoutFull children={<Control.DetailsRewardProvider {...x} />} />)} />

            <Route exact path='/AdminCompany' render={x => (<LayoutFull children={<Control.AdminCompany {...x} />} />)} />
            <Route exact path='/details-company/:id' render={x => (<LayoutFull children={<Control.DetailsCompany  {...x} />} />)} />

            <Route exact path='/course' render={x => (<LayoutFull children={<Admin.AdminCourse />} />)} />
            <Route exact path='/course-view' render={x => (<LayoutFull children={<Pages.CourseView />} />)} />
            <Redirect from='/' to='/home' />

        </Switch>

    )
}