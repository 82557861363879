import React, { useRef, useState } from 'react';
import SearchGalleries from '../../../components/companyPost/searchGalleries';
import './galleries.scss'

import useInputSingleForm from '../../../components/CustonHooks/inputFormHook';
import { storageService } from '../../../services/storage.service';
import { companyPostService } from '../../../services/companyPost.service';
import { SelectUsers } from "../../../components/Helpers/selectUsers";
import { AudienceUsers } from "../../../components/Helpers/audienceUsers";


import { useDropzone } from 'react-dropzone';
import './galleries.scss'
import swal from "sweetalert"


export function AdminGallery() {

    let companyInit = { CompanyPostId: 0, Title: '', Subtitle: '', Text: '', DepartmentId: 0, JobRoleId: 0 }
    const [showEdit, setShowEdit] = useState(false);
    const [userSelec, setUserSelec] = useState([]);
    const [images, setImages] = useState([]);
    const [postType, setPostType] = useState(1);
    const gridRef = useRef();

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));


    const removeAllFiles = () => {
        acceptedFiles.length = 0
        acceptedFiles.splice(0, acceptedFiles.length)
    }

    const onSave = () => {
        if (userSelec.length > 0) {
            let model = {
                CompanyPostId: parseInt(inputs.CompanyPostId), Title: inputs.Title, Subtitle: inputs.Subtitle, Text: inputs.Text, PostType: postType, ListUser: userSelec
            }

            if (parseInt(inputs.CompanyPostId) === 0) {
                companyPostService.create(model, acceptedFiles).then((result) => {
                    gridRef.current.refreshDataGrid();

                });

            } else {
                companyPostService.update(model, acceptedFiles).then((result) => {
                    gridRef.current.refreshDataGrid();
                });
            }
            setUserSelec([]);
            setInputs({});
            setShowEdit(false);
            setPostType(1);
            setImages([]);
        } else
            swal({ text: "Debes elegir al menos un usuario", icon: "error" });
    }

    const { inputs, handleInputChange, handleSubmit, setInputs } = useInputSingleForm(onSave, {});

    const handleOnCancel = () => {
        setInputs({});
        setShowEdit(false);
        setUserSelec([]);
        setPostType(1);
        setImages([]);
    }


    const handleOnNewGallery = () => {
        setInputs(companyInit);
        setShowEdit(true);
        setUserSelec([]);
        setImages([]);
        setPostType(1);
    };
    const handleRowSelect = (gridItem) => {
        removeAllFiles();
        setShowEdit(true);
        setPostType(gridItem.postType);

        setInputs({ CompanyPostId: gridItem.companyPostId, Title: gridItem.title, Subtitle: gridItem.subtitle, Text: gridItem.text, PostType: gridItem.postType });
        companyPostService.getCompanyPostImagesPathById(gridItem.companyPostId).then((result) => {
            setImages(result.files);
        });
    }

    const handleDeleteImageGallery = (entityId, entityTypeId, sequence) => {
        storageService.deleteStorage({ EntityId: entityId, EntityTypeId: entityTypeId, Sequence: sequence })
        setImages(
            images.filter(function (image) {
                return image.sequence !== sequence
            })
        );
    };

    return (
        <div className="wrapper_galleries">
            {!showEdit ?
                <div>
                    <div className="mb-4 mt-4 divtop ">
                        <div className="box_ttl_opt no_reverse">
                            <h3 className="mb-0">Lista de Álbumes</h3>
                            <div className="box_actions ">
                                <button
                                    type="button"
                                    className="btn secundary mr-2"
                                    onClick={handleOnNewGallery}
                                >
                                    <i className="fas fa-plus"></i> Agregar álbum
                            </button>
                            </div>
                        </div>
                    </div>
                    <SearchGalleries handleRowSelect={handleRowSelect} ref={gridRef} />
                </div>
                : null}

            {showEdit ?
                <div className="wrapper_galleries">
                    <form onSubmit={handleSubmit}>
                        <fieldset className='mt-4 mb-4'>
                            <legend className="text fnt_medium">Álbum</legend>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label for="Title">Título de álbum </label>
                                        <input type="text" className="form-control" name="Title" placeholder="Nombre del álbum" onChange={handleInputChange} value={inputs.Title} required />
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <div className="wrapper_dropzone">
                            <aside className="mt-4 mb-4">
                                <h6 className="text fnt_medium">Archivos seleccionado:</h6>
                                <ul>{files}</ul>
                                <div className="box_imgs_gallery">
                                    {images.map((image, index) => (
                                        <div key={index} className="item_img_gallery">
                                            <img src={image.path} alt={image.fileName} />
                                            <span className="ico_trash" onClick={() => handleDeleteImageGallery(image.entityId, image.entityTypeId, image.sequence)}><i className="far fa-trash-alt"></i></span>
                                        </div>
                                    ))}
                                </div>
                            </aside>
                            <div {...getRootProps({ className: 'dropzone shadows bradius' })}>
                                <input {...getInputProps()} accept="image/*" />
                                <div className="box_dropzone">

                                    <span><i className="fas fa-download"></i></span>
                                    <p >Arrastre y suelte las imágenes aquí, o haga clic para seleccionar un archivo.</p>
                                </div>
                            </div>

                        </div>

                        {inputs.CompanyPostId > 0 ?
                            <AudienceUsers targetId={inputs.CompanyPostId} eventType="Galeria" selectUser={setUserSelec} />
                            : <SelectUsers
                                selectUser={setUserSelec}
                                title={"Entrada dirigida a:"}
                            />}
                        <div className="text-right mt-4">
                            <button className="btn ml-1 mr-1 secundary" type="button" onClick={handleOnCancel}>Cancelar</button>
                            <input className="btn ml-1 mr-1 primary" type="submit" value="Guardar" />
                        </div>
                    </form>
                </div>
                : null}

        </div>
    )
}