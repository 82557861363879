import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { rewardProductService } from '../../services/control/rewardProduct.service';

export function SearchReward(props) {
    const [rewardsData, setRewardsData] = useState({ rewards: [] });
    const handleDelete = (data) => {
        rewardProductService.deleteRewardProduct(data).then((result) => { getRewardProducts(); });
    };

    useEffect(() => {
        getRewardProducts();
    }, [props.refresh]);

    async function getRewardProducts() {
        rewardProductService.get().then((result) => {
            if (result) {
                setRewardsData({ rewards: result });
            }
        });
    }


    const getPreview = async (id) => {
        rewardProductService.getTestCoupon(id);
    }

    return (
        <div className="table-responsive card shadows p-3">
            <table className="wrapper_table table table-hover table-sm">

                <thead>
                    <tr>
                        <th className="text fnt_medium">ID
					</th>
                        <th className="text fnt_medium min_width">Producto
					</th>
                        <th className="text fnt_medium">Mostrar folio
					</th>
                        <th className="text fnt_medium">Código de barras
					</th>
                        {/*
                        <th className="text fnt_medium">URL
					</th>
                        */}
                        <th className="text fnt_medium">Puntos
					</th>
                        <th className="text fnt_medium">Activo
					</th>
                        <th className="text fnt_medium">Folios disponibles
					</th>
                    </tr>
                </thead>
                <tbody>
                    {rewardsData.rewards.map(reward => (
                        <tr key={reward.rewardProductId}>
                            <td>
                                <Link className="link_data" to={"/details-reward/" + reward.rewardProductId}>{reward.rewardProductId}</Link>
                            </td>
                            <td>
                                <Link className="link_data" to={"/details-reward/" + reward.rewardProductId}>{reward.title}</Link>
                            </td>
                            <td>
                                <Link className="link_data" to={"/details-reward/" + reward.rewardProductId}>{reward.isVisible ? "Si" : "No"}</Link>
                            </td>
                            <td>
                                <Link className="link_data" to={"/details-reward/" + reward.rewardProductId}>{reward.isBarCode ? "Si" : "No"}</Link>
                            </td>
                            {/*
                            <td>
                                {reward.urlImage}
                            </td>
                            */}
                            <td>
                                {reward.points}
                            </td>
                            <td>{reward.active ? "Activo" : "Inactivo"}</td>
                            <td>
                                {reward.stock}
                            </td>

                            <td>
                                <span className="icon_tables" onClick={() => handleDelete(reward.rewardProductId)}>
                                    <i className="far fa-trash-alt"></i>
                                </span>
                            </td>
                            <td>
                                <Link className="link_data" to={"/load-coupons/" + reward.rewardProductId}>Cargar</Link>
                            </td>
                            <td>
                                {
                                    reward.urlPdf != null ?
                                        <button type="button" className="btn primary" onClick={() => getPreview(reward.rewardProductId)}>Visualizar</button>
                                        : null
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    );
}