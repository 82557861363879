import React, { useState, useEffect } from 'react'
import { ItemMorePost } from './itemMorePost';
import { companyPostViewService } from '../../services/compayPostView.service';

export function AsideOurCompany({ getPost, postType, activeAsideSurvey, onCloseClick }) {
	const [filter, setFilter] = useState([]);

	useEffect(() => {
		companyPostViewService.getCompanyPostFilter(postType)
			.then((result) => {
				if (result) {
					setFilter(result);
				}
			});
	}, [postType]);


	return (
		<div className={`sec_aside_our_company trans ${activeAsideSurvey}`}>
			<span className="closex" onClick={onCloseClick}><i className="fas fa-times"></i></span>
			<div className="sec_more_post">
				<h5 className="mb-0 text fnt_medium text-muted pb-2">Más publicaciones</h5>
				<div className="box_items_years">
					{filter.map(item => <ItemMorePost filter={item} getPost={getPost} />)}
				</div>
			</div>
		</div>
	)
}