import React, { useState } from "react";
import { Link } from "react-router-dom";
import bgRegister from "../../assets/imgs/bg_register.jpg";
import LogoCompany from "../../assets/imgs/R_C.png";
import "./register.scss";
import { userService } from "../../services/user.service";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaPage1, schemaPage2 } from "./registerSchema";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ImgCache } from "../../components/CustonHooks/imgCache";
import { Legals } from "../../components/legals/legals";
import moment from 'moment';
const Page1 = ({ register, errors }) => {
    return (
        <div className="box_form_user">
            <h5 className="text fnt_medium text-center">Datos de usuario</h5>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="firstName">Nombre(s)</label>
                        <input
                            type="text"
                            className={`form-control ${errors.firstName && "is-invalid"}`}
                            placeholder="Nombre(s)"
                            {...register("firstName")}
                        />
                        <div className="invalid-feedback">{errors.firstName?.message}</div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="middleName">Apellido Paterno</label>
                        <input
                            type="text"
                            className={`form-control ${errors.middleName && "is-invalid"}`}
                            placeholder="Apellido Paterno"
                            {...register("middleName")}
                        />
                        <div className="invalid-feedback">{errors.middleName?.message}</div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="lastName">Apellido Materno</label>
                        <input
                            type="text"
                            className={`form-control ${errors.lastName && "is-invalid"}`}
                            placeholder="Apellido Materno"
                            {...register("lastName")}
                        />
                        <div className="invalid-feedback">{errors.lastName?.message}</div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <p>Sexo</p>
                        <div
                            className={`custom-control custom-radio custom-control-inline ${errors.gender && "is-invalid"
                                }`}
                        >
                            <input
                                type="radio"
                                id="genderM"
                                className="custom-control-input"
                                value="M"
                                {...register("gender")}
                            />
                            <label className="custom-control-label" htmlFor="genderM">
                                Masculino
              </label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                            <input
                                type="radio"
                                id="genderF"
                                className="custom-control-input"
                                value="F"
                                {...register("gender")}
                            />
                            <label className="custom-control-label" htmlFor="genderF">
                                Femenino
              </label>
                        </div>
                        <div className="is-invalid invalid-feedback">
                            {errors.gender?.message}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="birthday">Fecha de Nacimiento</label>
                        <input
                            type="date"
                            className={`form-control ${errors.birthday && "is-invalid"}`}
                            {...register("birthday")}
                            min='1900-01-01'
                            max={moment(new Date()).format('YYYY-MM-DD')}
                        />
                        <div className="invalid-feedback">{errors.birthday?.message}</div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="mobile">
                            Teléfono Personal <strong>(Opcional)</strong>
                        </label>
                        <input
                            type="text"
                            className={`form-control ${errors.mobile && "is-invalid"}`}
                            placeholder="5555555555"
                            {...register("mobile")}
                        />
                        <div className="invalid-feedback">{errors.mobile?.message}</div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="phoneNumber">
                            Teléfono Oficina <strong>(Opcional)</strong>
                        </label>
                        <input
                            type="text"
                            className={`form-control ${errors.phoneNumber && "is-invalid"}`}
                            placeholder="5555555555"
                            {...register("phoneNumber")}
                        />
                        <div className="invalid-feedback">
                            {errors.phoneNumber?.message}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="extension">
                            Extensión <strong>(Opcional)</strong>
                        </label>
                        <input
                            type="text"
                            className={`form-control ${errors.extension && "is-invalid"}`}
                            placeholder="Extensión"
                            {...register("extension")}
                        />
                        <div className="invalid-feedback">{errors.extension?.message}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Page2 = ({ register, errors }) => {
    const [showPass, setShowPass] = useState("password");
    const [showIco, setShowIco] = useState("fa-eye-slash");

    const setInputPass = () => {
        setShowPass(showPass === "password" ? "text" : "password");
        setShowIco(showIco === "fa-eye-slash" ? "fa-eye" : "fa-eye-slash");
    };

    return (
        <div className="box_form_account">
            <h5 className="text fnt_medium text-center">Datos de la cuenta</h5>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="email">Correo Electrónico</label>
                        <input
                            type="text"
                            className={`form-control ${errors.email && "is-invalid"}`}
                            aria-describedby="emailHelp"
                            placeholder="correo@dominio.com"
                            {...register("email")}
                        />
                        <div className="invalid-feedback">{errors.email?.message}</div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group box_input_icons">
                        <label htmlFor="password">Contraseña</label>
                        <input
                            type={showPass}
                            className={`form-control ${errors.password && "is-invalid"}`}
                            aria-describedby="passHelp"
                            placeholder="Contraseña"
                            {...register("password")}
                        />
                        <span className="ico_show_pass" onClick={setInputPass}>
                            <i className={`fas ${showIco}`}></i>
                        </span>
                        <div className="invalid-feedback">{errors.password?.message}</div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group box_input_icons">
                        <label htmlFor="confirmPassword">Confirmar Contraseña</label>
                        <input
                            type={showPass}
                            className={`form-control ${errors.confirmPassword && "is-invalid"
                                }`}
                            aria-describedby="passConfHelp"
                            placeholder="Confirmar Contraseña"
                            {...register("confirmPassword")}
                        />
                        <span className="ico_show_pass" onClick={setInputPass}>
                            <i className={`fas ${showIco}`}></i>
                        </span>
                        <div className="invalid-feedback">
                            {errors.confirmPassword?.message}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export function Register(props) {
    const companyLogo = useSelector((state) => state.userSettings.companyLogo);
    const accountSettings = useSelector((state) => state.accountSettings);

    if (!accountSettings.activateRegistration) props.history.push("/login");

    const [activePage, setActivePage] = useState(0);
    const [schema, setSchema] = useState();

    useEffect(() => {
        // form validation rules
        if (activePage == 0) setSchema(schemaPage1);
        else setSchema(schemaPage2);
    }, [activePage]);

    // get functions to build form with useForm() hook
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const onSubmit = (data) => {
        if (activePage == 0) nextPage();
        else registerUser(data);
    };

    const registerUser = (data) => {
        data.companyId = parseInt(process.env.REACT_APP_COMPANY);
        userService.register(data);
    };

    const nextPage = () => {
        setActivePage(activePage + 1);
    };

    const prevPage = () => {
        setActivePage(activePage - 1);
    };

    return (
        <section className="wrapper_register">
            <img
                className="bg_register"
                src={bgRegister}
                alt="Registrate en Kuimby Social"
            />
            <div className="box_header">
                <Link className="trans " to="/login">
                    Ya tengo cuenta
        </Link>
            </div>
            <div className="container container_register">
                <div className="drawer_card_register">
                    <div className="box_brand_register">
                        <div>
                            <h3 className="m-0 text fnt_bold c_primary line">
                                Registro nuevo
              </h3>
                            {/*<p className="m-0 text-muted">*/}
                            {/*    Lorem, ipsum dolor sit amet consectetur adipisicing elit.*/}
                            {/*</p>*/}
                        </div>
                        <ImgCache
                            src={companyLogo ? companyLogo : LogoCompany}
                            alt="Logo oficial Kuimby Social"
                        />
                    </div>
                    <div
                        className={`box_points_step ${activePage === 1 ? "bar_full" : ""} `}
                    >
                        <div className={`point_step text fnt_bold active`}>1</div>
                        <div
                            className={`point_step text fnt_bold ${activePage === 1 ? "active" : ""
                                }`}
                        >
                            2
            </div>
                    </div>
                    <div className="box_form_register">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {
                                {
                                    0: <Page1 register={register} errors={errors} />,
                                    1: <Page2 register={register} errors={errors} />,
                                }[activePage]
                            }
                            <hr />
                            <div className="box_form_btns text-right mt-2">
                                {activePage > 0 && (
                                    <button
                                        type="submit"
                                        className="btn secundary "
                                        onClick={prevPage}
                                    >
                                        Anterior
                                    </button>
                                )}
                                <button type="submit" className="btn primary">
                                    {activePage == 1 ? "Registrar" : "Siguiente"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <Legals />
            </div>
        </section>
    );
}
