import React from 'react';
import ItemPurchase from '../itemPurchase/itemPurchase'
import BoxPayMethod from '../boxPayMethod/boxPayMethod'
import './asidePurchase.scss'
import LogRedPay from '../../assets/imgs/red-pay-logo.png'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setCartQuantity } from '../../store/actions';
import { cartHelper } from '../../helpers/cart.helper';
import swal from "sweetalert";

const AsidePurchase = ({ activeAside, onCloseClick, cart, total, setCart, setTotal }) => {
    const dispatch = useDispatch();
    const countrySetting = useSelector(state => state.countrySetting);

    const onChangeQuantity = (providerId, productId, value) => {
        cartHelper.onChangeQuantity(providerId, productId, parseInt(value))
            .then(function () {
                setTotal(JSON.parse(localStorage["cartTotalKuimby"]));
                setCart(JSON.parse(localStorage["cartKuimby"]));
                dispatch(setCartQuantity());
            });
    }

    const deleteItem = (providerId, providerName) => {
        swal({
            title: "¿Estas seguro?",
            text: "¡Desea eliminar los productos de " + providerName + " del carrito de compras!",
            icon: "warning",
            dangerMode: true,
            buttons: ["Cancelar", "Si, eliminar"]
        })
            .then((willDelete) => {
                if (willDelete) {
                    cartHelper.deleteItem(providerId)
                        .then(function () {
                            setTotal(JSON.parse(localStorage["cartTotalKuimby"]));
                            setCart(JSON.parse(localStorage["cartKuimby"]));
                            dispatch(setCartQuantity());
                        });
                }
            });
    }

    const deleteItemDetail = (providerId, productId) => {
        swal({
            title: "¿Estas seguro?",
            text: "¡Desea eliminar este productos del carrito de compras!",
            icon: "warning",
            buttons: ["Cancelar", "Si, eliminar"],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    cartHelper.deleteItemDetail(providerId, productId)
                        .then(function () {
                            setTotal(JSON.parse(localStorage["cartTotalKuimby"]));
                            setCart(JSON.parse(localStorage["cartKuimby"]));
                            dispatch(setCartQuantity());
                        });
                }
            });
    }

    return <React.Fragment>
        <aside className={`wrapper_aside_purchase trans ${activeAside}`}>
            <div className="box_content_aside">
                <div className="titulares box_flex ">
                    <h5 className="text fnt_medium  m-0"><i className="fas fa-shopping-cart"></i> Tu carrito de compras</h5>
                    <div className="btn_brd" onClick={onCloseClick}><i className="fas fa-times-circle"></i> <span>Cerrar</span></div>
                </div>
                <div className="content_items">
                    {
                        cart.map(item => {
                            return < ItemPurchase {...item} deleteItem={deleteItem} deleteItemDetail={deleteItemDetail} onChangeQuantity={onChangeQuantity} />
                        })
                    }
                </div>
                <div className="box_footer_aside ttls_sections">
                    <div className="box_total_neto">
                        <div>
                            <h5 className="text fnt_medium c_primary mb-1">¡FELICIDADES!</h5>
                            <p className="label">Estas a punto de vivir una experiencia increíble.</p>
                        </div>
                        <div className="box_w">
                            <h4 className="text fnt_medium">Total: {countrySetting.currency}{total.toString().toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</h4>
                        </div>
                    </div>
                    <div className="wrapper_paymethod">
                        <h6 className="ttls_sections"><span>Elige tu método de pago</span></h6>
                        <BoxPayMethod onCloseClick={onCloseClick} />
                        <div className="box_powered_by">
                            <small className="font-italic fw500">Powered by</small>
                            <a target="_blank" href="https://redpay.mx">
                                <img src={LogRedPay} alt="RedPay" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
        <div className="bg_pleca trans" onClick={onCloseClick}></div>
    </React.Fragment>
}

export default AsidePurchase