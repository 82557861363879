import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { useDiary } from '../../../services/diary.service';

export function ShowDiaryDates(props) {
	const [calendarDate, setCalendarDate] = useState({ date: null });
	const localizer = momentLocalizer(moment)
	const [DiaryData, setDiaryData] = useState({ Diary: [] });
	const [CurrentRange, setCurrentRange] = useState({ startDate: null, endDate: null });
	const customEventPropGettet = (event, start, end, isSelected) => {
		if (event.diaryTypeId === 0) {
			return {
				className: 'event-event'
			}
		}
		else
			return {
				className: 'event-birthday'
			}
	}
	useEffect(() => {
		handleNavigate(new Date(), "month");
	}, []);
	useEffect(() => {
		getCompanyDiary();
	}, [props.refresh]);
	useEffect(() => {
		getCompanyDiary();
	}, [CurrentRange]);

	async function handleNavigate(date, view) {
		let start, end;

		setCalendarDate({ date: date });
		if (view === 'month') {
			start = moment(date).startOf('month').startOf('week').format('YYYY-MM-DD');
			end = moment(date).endOf('month').endOf('week').format('YYYY-MM-DD');
		}
		else if (view === 'week') {
			start = moment(date).startOf('isoWeek').format('YYYY-MM-DD');
			end = moment(date).endOf('isoWeek').format('YYYY-MM-DD');
		}
		else if (view === 'day') {
			start = moment(date).startOf('day').format('YYYY-MM-DD');
			end = moment(date).endOf('day').format('YYYY-MM-DD');
		}
		else if (view === 'agenda') {
			start = moment(date).startOf('day').format('YYYY-MM-DD');
			end = moment(date).endOf('day').add(1, 'month').format('YYYY-MM-DD');
		}
		var range = { startDate: start, endDate: end };
		setCurrentRange(range);
	}
	async function handleView(view) {
		let start, end;
		if (calendarDate.date) {
			if (view === 'month') {
				start = moment(calendarDate.date).startOf('month').startOf('week').format('YYYY-MM-DD');
				end = moment(calendarDate.date).endOf('month').endOf('week').format('YYYY-MM-DD');
			}
			else if (view === 'week') {
				start = moment(calendarDate.date).startOf('isoWeek').format('YYYY-MM-DD');
				end = moment(calendarDate.date).endOf('isoWeek').format('YYYY-MM-DD');
			}
			else if (view === 'day') {
				start = moment(calendarDate.date).startOf('day').format('YYYY-MM-DD');
				end = moment(calendarDate.date).endOf('day').format('YYYY-MM-DD');
			}
			else if (view === 'agenda') {
				start = moment(calendarDate.date).startOf('day').format('YYYY-MM-DD');
				end = moment(calendarDate.date).endOf('day').add(1, 'month').format('YYYY-MM-DD');
			}
			var range = { startDate: start, endDate: end };
			setCurrentRange(range);
		}
	}
	async function getCompanyDiary() {
		useDiary.getCompanyDiaryFiltered(CurrentRange.startDate, CurrentRange.endDate, null).then((result) => {
			if (result) {
				result.forEach(d => {
					d.startDate = new Date(d.startDate)
					d.endDate = new Date(d.endDate)
				});
				setDiaryData({ Diary: result });
			}
		});
	}
	async function handleEventSelected(event, e) {
		if (props.onEventSelected)
			props.onEventSelected(event);
	}
	const messages = {
		allDay: 'Todo el día',
		previous: '<',
		next: '>',
		today: 'Hoy',
		month: 'Mes',
		week: 'Semana',
		day: 'Día',
		agenda: 'Agenda',
		date: 'Fecha',
		time: 'Horario',
		event: 'Descripción del evento',
		noEventsInRange: 'No hay eventos en este rango.',
	}
	return (
		<div >

			<div className="mb-3 divtop">
				<div className="box_ttl_opt mb-0">
					<h2 className="mb-0 text fnt_medium">Calendario</h2>
					<div className="box_actions">
						<button type="button" className="btn secundary mr-2" onClick={props.onNewClicked}><i className="fas fa-plus"></i> Agregar Evento</button>
					</div>
				</div>
				<div className="box_ttl_opt">
					<h6 className="mb-0 text-muted">Administrador</h6>
				</div>
			</div>
			<div className="box_data_calendar mb-4">
				<div className="item_data_calendar">
					<span className="drops event"></span>
					<h6>Evento general</h6>
				</div>
				<div className="item_data_calendar">
					<span className="drops birthday"></span>
					<h6>Cumpleaños</h6>
				</div>
			</div>

			<div className="wrapper_calendar" style={{ height: 500 }}>
				<Calendar
					localizer={localizer}
					events={DiaryData.Diary}
					titleAccessor="name"
					allDayAccessor="allDay"
					startAccessor="startDate"
					endAccessor="endDate"
					onNavigate={handleNavigate}
					onView={handleView}
					eventPropGetter={customEventPropGettet}
					onSelectEvent={handleEventSelected}
					messages={messages}
				/>
			</div>

		</div>
	);
}