import React, { useState, useEffect } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import { userProfileService } from '../../services/backoffice/userProfile.service'
import moment from 'moment';
import { useForm } from 'react-hook-form';
import './users.scss'
import { useSelector } from 'react-redux';


export function UserDetails(props) {
    const companyId = useSelector(state => state.userSettings.companyId)
    const [catalogs, setCatalogs] = useState({ aspNetRolesCatalog: [], branchOfficeCatalog: [], departmentCatalog: [], jobRoleCatalog: [] });
    const [isNew, setIsNew] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const { register, reset, handleSubmit } = useForm();

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        userProfileService.getCatalogs()
            .then((result) => {
                setCatalogs(result);
                setIsNew(props.match.params.id == "new");
                if (!isNew) {
                    getById(props.match.params.id);
                }
            });

    }, [props.match.params.id]);

    const getById = (id) => {
        userProfileService.getById(id)
            .then((result) => {
                result.userId = result.aspNetUserId;
                result.birthday = moment(result.birthday).format('YYYY-MM-DD')
                result.hiringDate = moment(result.hiringDate).format('YYYY-MM-DD')
                reset(result);
            });
    }

    const onSubmit = (data, e) => {
        data.companyId = companyId;
        data.notification = data.notification == 'true';
        data.branchOfficeId = parseInt(data.branchOfficeId);
        data.departmentId = parseInt(data.departmentId);
        data.jobRoleId = parseInt(data.jobRoleId);

        if (isNew)
            userProfileService.create(data);
        else
            userProfileService.edit(data);
    }

    return (
        <section className="wrapper_users_details padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt mb-1">
                    <h2 className="mb-0 text fnt_medium">Usuario</h2>
                </div>
                <div className="box_ttl_opt">
                    <h4 className="mb-0">{isNew ? "Usuario nuevo" : "Detalle del usuario"}</h4>
                </div>
            </div>

            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                        >
                            Información general
                    </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="card_tabs">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <input type="hidden" {...register("userId")} />
                                <input type="hidden" {...register("preferences")} />
                                <input type="hidden" {...register("nickname")} />
                                <input type="hidden" {...register("notification")} />
                                <input type="hidden" {...register("urlProfileImage")} />
                                <fieldset className='mt-4 mb-5'>
                                    <legend className="text fnt_medium">Datos de la cuenta</legend>
                                    <div className="row">
                                        <div className="col-sm-6 col-lg-2 mb-2">
                                            <label for="active">Status</label>
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" id="active" className="custom-control-input" {...register("active")} />
                                                <label className="custom-control-label" for="active">Usuario activo</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-4">
                                            <div className="form-group">
                                                <label for="roleId">Perfíl</label>
                                                <select className="form-control" {...register("roleId")}>
                                                    {
                                                        catalogs.aspNetRolesCatalog.map(item => <option key={item.value} value={item.value}> {item.label}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-lg-4">
                                            <div className="form-group">
                                                <label for="email">Correo electrónico</label>
                                                <input type="email" className="form-control" aria-describedby="emailHelp" placeholder="correo@miempresa.com" {...register("email")} />
                                            </div>
                                        </div>
                                        {
                                            isNew ?
                                                <div className="col-sm-6 col-lg-4">
                                                    <div className="form-group">
                                                        <label for="exampleInputPassword1">Password</label>
                                                        <input type="password" className="form-control" placeholder="Password" {...register("password")} />
                                                    </div>
                                                </div>
                                                : ""
                                        }
                                    </div>
                                </fieldset>

                                <fieldset className='mb-5'>
                                    <legend className="text fnt_medium">Datos de usuario</legend>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="">No. Usuario</label>
                                                <input type="text" className="form-control" placeholder="xxx-xxx" {...register("employeeNo")} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="">Nombre(s)</label>
                                                <input type="text" className="form-control" placeholder="Nombre(s)" {...register("firstName")} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="">Apellido paterno</label>
                                                <input type="text" className="form-control" placeholder="Apellido paterno" {...register("middleName")} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="">Apellido materno</label>
                                                <input type="text" className="form-control" placeholder="Apellido materno" {...register("lastName")} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="gender">Género</label>
                                                <select className="form-control" {...register("gender")} >
                                                    <option value="M">Masculino</option>
                                                    <option value="F">Femenino</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="birthday">Fecha de nacimiento</label>
                                                <input className="form-control" type="date" min='1900-01-01' max={moment(new Date()).format('YYYY-MM-DD')} {...register("birthday")}/>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="">Tel. Personal</label>
                                                <input type="text" className="form-control" placeholder="Tel. Personal" {...register("mobile")} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="">Tel. Oficina</label>
                                                <input type="text" className="form-control" placeholder="Tel. Oficina" {...register("phoneNumber")} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="">Ext.</label>
                                                <input type="text" className="form-control" placeholder="Ext." {...register("extension")} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="hiringDate">Fecha de ingreso</label>
                                                <input className="form-control" type="date" {...register("hiringDate")} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="additionalEmail">Correo adicional</label>
                                                <input className="form-control" type="text" {...register("additionalEmail")} />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="branchOfficeId">Sucursal</label>
                                                <select className="form-control" {...register("branchOfficeId")} >
                                                    {
                                                        catalogs.branchOfficeCatalog.map(item => <option key={item.value} value={item.value}> {item.label}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="departmentId">Grupo</label>
                                                <select className="form-control" {...register("departmentId")} >
                                                    {
                                                        catalogs.departmentCatalog.map(item => <option key={item.value} value={item.value}> {item.label}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label for="jobRoleId">Categoría</label>
                                                <select className="form-control" {...register("jobRoleId")} >
                                                    {
                                                        catalogs.jobRoleCatalog.map(item => <option key={item.value} value={item.value}> {item.label}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <div className="text-right mb-4">
                                    <button type="submit" className="btn primary">Guardar</button>
                                </div>
                            </form>
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        </section>
    )
}