import React, { useState, useEffect } from 'react'
import { WrapperCategoriesRewards } from './wrapperCategoriesRewards'
import { HeadRewards } from './headRewards'
import './rewards.scss'
import { rewardsService } from "../../services/rewards.service";

export function Rewards() {
    const [reward, setReward] = useState([]);
    const [rewardCategory, setRewardCategory] = useState([]);
    const [rewardProducts, setRewardProducts] = useState([]);

    useEffect(() => {
        rewardsService.rewardCategories()
            .then((result) => {
                setRewardCategory(result);
                getRewardProducts(0);
            });
    }, []);

    const getRewardProducts = async (rewardCategory) => {
        rewardsService.rewardProducts(rewardCategory)
            .then((result) => {
                setReward(result.reward);
                setRewardProducts(result.items);
            });
    }

    return (
        <section className="wrapper_rewards ">
            <HeadRewards reward={reward} />
            <WrapperCategoriesRewards reward={reward} rewardCategory={rewardCategory} rewardProducts={rewardProducts} getRewardProducts={getRewardProducts} />
        </section>
    )
}