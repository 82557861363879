import React, { useRef, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { EditorPost } from "./editor";

import { Form, FormGroup, Label, Input } from "reactstrap";
import useInputSingleForm from "../../components/CustonHooks/inputFormHook";
import { GeneralUpload } from "../../components/Helpers/generalUpload";
import SearchCompanyPost from "./searchCompanyPost";
import { companyPostService } from "../../services/companyPost.service";
import { SelectUsers } from "../../components/Helpers/selectUsers";
import { AudienceUsers } from "../../components/Helpers/audienceUsers";
import { CompanyPostAttachment } from "../../components/companyPost/companyPostAttachment";
import { storageService } from "../../services/storage.service";

import "./ourCompanyAdmin.scss";

import { AdminGallery } from "./galleries/adminGallery";
import swal from "sweetalert";

export function CompanyPost() {
    const [activeTab, setActiveTab] = useState("1");

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    let companyInit = {
        CompanyPostId: 0,
        Title: "",
        Subtitle: "",
        Text: "",
        DepartmentId: 0,
        JobRoleId: 0,
    };
    const [localfiles, setLocalFiles] = useState([]);
    const [localAttachments, setLocalAttachments] = useState([]);
    const [showEdit, setShowEdit] = useState(false);
    const [userSelec, setUserSelec] = useState([]);
    const [images, setImages] = useState([]);
    const gridRef = useRef();
    const onSave = () => {
        let model = {
            CompanyPostId: parseInt(inputs.CompanyPostId),
            Title: inputs.Title,
            Subtitle: inputs.Subtitle,
            Text: inputs.Text,
            ListUser: userSelec,
        };

        if (userSelec.length > 0) {
            if (parseInt(inputs.CompanyPostId) === 0) {
                companyPostService
                    .create(model, localfiles, localAttachments)
                    .then((result) => {
                        gridRef.current.refreshDataGrid();
                    });

            } else {
                companyPostService
                    .update(model, localfiles, localAttachments)
                    .then((result) => {
                        gridRef.current.refreshDataGrid();
                    });
            }
            setUserSelec([]);
            setInputs({});
            setShowEdit(false);
            setLocalFiles([]);
            setLocalAttachments([]);
        } else {
            swal({ text: "Debes elegir al menos un usuario", icon: "error" });
        }
    };
    const {
        inputs,
        handleInputChange,
        handleSubmit,
        setInputs,
    } = useInputSingleForm(onSave, {});

    const handleOnCancel = () => {
        setInputs({});
        setShowEdit(false);
        setUserSelec([]);
        setLocalFiles([]);
        setLocalAttachments([]);
    };

    const handleOnChangeFile = (files) => {
        setLocalFiles(files);
    };

    const handleOnChangeAttach = (attachments) => {
        setLocalAttachments(attachments);
    };

    const handleOnNew = () => {
        setInputs(companyInit);
        setShowEdit(true);
        setUserSelec([]);
        setImages([]);
        setLocalFiles([]);
        setLocalAttachments([]);
    };
    const handleRowSelect = (gridItem) => {
        setShowEdit(true);
        setInputs({
            CompanyPostId: gridItem.companyPostId,
            Title: gridItem.title,
            Subtitle: gridItem.subtitle,
            Text: gridItem.text,
        });

        companyPostService
            .getCompanyPostImagesById(gridItem.companyPostId)
            .then((result) => {
                setImages(result.files);
            });
    };

    const handleDeleteImage = (event) => {
        storageService
            .deleteStorage({
                EntityId: images[0].entityId,
                EntityTypeId: images[0].entityTypeId,
                Sequence: images[0].sequence,
            })
            .then((result) => {
                setImages([]);
            });
        event.stopPropagation();
    };

    return (
        <section className="wrapper_post_admin padd">
            <div className="mb-4 divtop ">
                <div className="box_ttl_opt no_reverse mb-0">
                    <h2 className="mb-0 text fnt_medium">Nuestro mundo</h2>
                </div>
                <div className="box_ttl_opt no_reverse">
                    <h6 className="mb-0 text-muted">Administrador</h6>
                </div>
            </div>

            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                            toggle("1");
                        }}
                    >
                        Artículos
          </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                            toggle("2");
                        }}
                    >
                        Galerías
          </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <div className="card_tabs">
                        {/* ==== LISTA DE ARTICULOS ==== */}
                        {!showEdit ? (
                            <div>
                                <div className="mb-4 mt-4 divtop ">
                                    <div className="box_ttl_opt no_reverse">
                                        <h3 className="mb-0">Lista de artículos</h3>
                                        <div className="box_actions ">
                                            <button
                                                type="button"
                                                className="btn secundary mr-2"
                                                onClick={handleOnNew}
                                            >
                                                <i className="fas fa-plus"></i> Agregar entrada
                      </button>
                                        </div>
                                    </div>
                                </div>
                                <SearchCompanyPost
                                    handleRowSelect={handleRowSelect}
                                    ref={gridRef}
                                />
                            </div>
                        ) : null}

                        {/* ==== LISTA DE NUEVO/EDITAR ==== */}
                        <div className="wrapper_editor_textual">
                            {showEdit ? (
                                <Form onSubmit={handleSubmit}>
                                    <fieldset className="mt-4 mb-4">
                                        <legend className="text fnt_medium">
                                            Información de la entrada
                    </legend>

                                        <div className="row align-items-center">
                                            <div className="col-md-6">
                                                <FormGroup>
                                                    <Label for="Title">Título de entrada</Label>
                                                    <Input
                                                        name="Title"
                                                        placeholder="Título de entrada"
                                                        onChange={handleInputChange}
                                                        value={inputs.Title}
                                                        required
                                                    ></Input>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <FormGroup>
                                                    {images !== null && images.length > 0 ? (
                                                        <div className="post_img ">
                                                            <img
                                                                src={`data:image/jpeg;base64,${images[0].file}`}
                                                                style={{ width: "150px" }}
                                                            />
                                                            <span
                                                                onClick={(event) => handleDeleteImage(event)}
                                                            >
                                                                <i className="far fa-trash-alt"></i>
                                                            </span>
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                                {images == null || images.length === 0 ? (
                                                    <GeneralUpload
                                                        TitleLabel={"Imagen de la entrada"}
                                                        handleOnChangeFiles={handleOnChangeFile}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row mt-5">
                                            <div className="col-lg-5">
                                                <GeneralUpload
                                                    TitleLabel={"Archivos adjuntos"}
                                                    IsMultipe={true}
                                                    handleOnChangeFiles={handleOnChangeAttach}
                                                />
                                            </div>
                                            <div className="col-lg-7">
                                                {inputs.CompanyPostId !== null &&
                                                    inputs.CompanyPostId > 0 ? (
                                                        <CompanyPostAttachment
                                                            CompanyPostId={inputs.CompanyPostId}
                                                            IsDelete={true}
                                                        />
                                                    ) : null}
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            {/* <div className="col-md-6">
												<FormGroup >
													<Label for="Text">Text</Label>
													<Input name="Text" placeholder="Text" onChange={handleInputChange} value={inputs.Text}></Input>
												</FormGroup>
											</div> */}

                                            <EditorPost
                                                content={inputs.Text}
                                                name="Text"
                                                handleInputChange={handleInputChange}
                                            />
                                        </div>
                                    </fieldset>
                                    {inputs.CompanyPostId > 0 ?
                                        <AudienceUsers targetId={inputs.CompanyPostId} eventType="Articulo" selectUser={setUserSelec} />
                                        : <SelectUsers
                                            selectUser={setUserSelec}
                                            title={"Entrada dirigida a:"}
                                        />}
                                    <div className="text-right mt-4">
                                        <button
                                            className="btn ml-1 mr-1 secundary"
                                            type="button"
                                            onClick={handleOnCancel}
                                        >
                                            Cancelar
                    </button>
                                        <input
                                            className="btn ml-1 mr-1 primary"
                                            type="submit"
                                            value="Guardar"
                                        />
                                    </div>
                                </Form>
                            ) : null}
                        </div>
                    </div>
                </TabPane>

                <TabPane tabId="2">
                    <div className="card_tabs">
                        <AdminGallery />
                    </div>
                </TabPane>
            </TabContent>
        </section>
    );
}
