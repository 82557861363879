import { callApi } from "./service"
import swal from "sweetalert"

export const rewardsService = {
    rewardsFilterByDate,
    rewardsStatement,
    redemptionHistory,
    rewardProducts,
    redeemCoupon,
    rewardCategories,
    downloadCoupon
};

async function rewardsFilterByDate() {
    return await callApi('/BackEnd/RewardsFilterByDate', 'GET')
        .then(result => {
            if (result.responseCode === "00")
                return result.data;
            else
                return [];
        });
}

async function rewardsStatement(param) {
    return await callApi('/BackEnd/RewardsStatement?dateRange=' + param, 'GET')
        .then(result => {
            if (result.responseCode === "00")
                return result.data;
            else
                return [];
        });
}

async function redemptionHistory(param) {
    return await callApi('/BackEnd/RedemptionHistory?dateRange=' + param, 'GET')
        .then(result => {
            if (result.responseCode === "00")
                return result.data;
            else
                return [];
        });
}


async function rewardProducts(param) {
    return await callApi('/BackEnd/RewardProducts?rewardCategoryId=' + param, 'GET')
        .then(result => {
            if (result.responseCode === "00")
                return result.data;
            else
                return [];
        })
        .catch(error => {
            console.log('error', error);
            return []
        });
}

async function redeemCoupon(param) {
    return await callApi('/BackEnd/RedeemCoupon', 'Post', param)
        .then(result => {
            if (result.responseCode === "00") {
                swal({ text: "Cupón canjeado exitosamente", icon: "success" })
                    .then(() => {
                        const link = document.createElement('a');
                        link.href = result.data.couponUrl;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                return true;
            } else {
                swal({ text: result.responseMessage, icon: "error" })
                return false
            }
        });
}

async function rewardCategories() {
    return await callApi('/BackEnd/RewardCategories', 'GET')
        .then(result => {
            if (result.responseCode === "00")
                return result.data;
            else
                return [];
        });
}

async function downloadCoupon(orderId) {
    return await callApi('/BackEnd/DownloadCoupon/' + orderId, 'GET' )
        .then(result => {
            if (result.responseCode === "00") {
                swal({ text: "Cupón descargado exitosamente", icon: "success" })
                    .then(() => {
                        const link = document.createElement('a');
                        link.href = result.data;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                return true;
            } else {
                swal({ text: result.responseMessage, icon: "error" })
                return false
            }
        });
}
