import React, { useState, useEffect } from 'react'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export function EditorMessage(props) {
	const [editorState, setEditorState] = useState()
	const [count, setCount] = useState(0)

	useEffect(() => {
		if (count < 2) {
			let html = props.content;
			let contentBlock = htmlToDraft(html);
			if (contentBlock) {
				let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
				let editorState = EditorState.createWithContent(contentState);
				setEditorState(editorState)
			}
			let i = count + 1
			setCount(i)
		}
	}, [props.content])

	const onEditorStateChange = (editorState) => {
		props.handleInputChange({ target: { name: props.name, value: draftToHtml(convertToRaw(editorState.getCurrentContent())) } })
		setEditorState(editorState)
	}

	return (
		<div className="wrapper_editor_textual">
			<Editor
				editorState={editorState}
				wrapperClassName="wrapper-class"
				editorClassName="editor-class"
				toolbarClassName="toolbar-class"
				onEditorStateChange={onEditorStateChange}
				toolbar={{ options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'remove', 'history'] }}
			/>
		</div>
	)
}