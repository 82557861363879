import { callApi } from './service'

export const useQuestion = {
    createQuestion,
    updateQuestion,
    deleteQuestion,
    getQuestions,
    getFrontQuestions
};
async function createQuestion(params) {
    return callApi('/backoffice/SurveyQuestion', 'POST', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function getQuestions(params) {
    return callApi('/backoffice/SurveyQuestion/' + params.surveyId, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function getFrontQuestions(params) {
    return callApi('/backend/SurveyQuestion/' + params.surveyId, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function updateQuestion(params) {
    return callApi('/backoffice/SurveyQuestion', 'PUT', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function deleteQuestion(params) {
    return callApi('/backoffice/SurveyQuestion', 'DELETE', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}