import React, { useState } from 'react';
import { SearchRewardCategory } from './searchRewardCategory';

import './rewardAdmin.scss'
import { Link } from 'react-router-dom';

export function AdminRewardCategory() {
    const [refresh, setRefresh] = useState(0);

    return (
        <section className="wrapper_reward_admin padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt no_reverse mb-0">
                    <div>
                        <h2 className="mb-0 text fnt_medium">Categorías</h2>
                        <h6 className="mb-0 text-muted">Operaciones</h6>
                    </div>
                    <div className="box_actions">
                        <Link className="btn primary" to={"/details-rewardCategory/0"}><i className="fas fa-plus"></i> Agregar Categoría</Link>
                    </div>
                </div>
            </div>
            <div>
                <SearchRewardCategory refresh={refresh} />
            </div>

        </section>
    );
}