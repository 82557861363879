import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./hubItemProvider.scss";
import { ecommerceService } from "../../services/ecommerce.service";

export class HubItemProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      providersOptions: [],
    };
  }

  componentWillMount() {
    // localStorage cart
      if (localStorage.getItem("cartKuimby") === undefined || localStorage.getItem("cartKuimby") === null)
          localStorage.setItem("cartKuimby", "[]");
      if (localStorage.getItem("cartTotalKuimby") === undefined || localStorage.getItem("cartTotalKuimby") === null)
          localStorage.setItem("cartTotalKuimby", 0);
      if (localStorage.getItem("cartQuantityKuimby") === undefined || localStorage.getItem("cartQuantityKuimby") === null)
          localStorage.setItem("cartQuantityKuimby", 0);

    ecommerceService.getProviders().then((result) => {
      this.setState({ providersOptions: result });
    });
  }

  render() {
    const { providersOptions } = this.state;

    return (
      <div className="box_container_hub">
        {providersOptions.map((providersOption) => {
          return (
            <Link
              to={"/provider/" + providersOption.id}
              className="item_provaider_hub "
              key={providersOption.id}
            >
              <p className="item_content">
                <p className="box_p_img">
                  <img src={providersOption.providerLogo} alt="" />
                </p>
              </p>
            </Link>
          );
        })}
      </div>
    );
  }
}
