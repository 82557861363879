import React, { useState } from 'react'
import { Collapse } from 'reactstrap';
import PurchasedItemDetails from '../purchasedItemDetails';
import './purchasedItem.scss';
import { useSelector } from 'react-redux';
import { ecommerceService } from "../../../../services/ecommerce.service";

const PurchasedItem = (props) => {
  const countrySetting = useSelector(state => state.countrySetting);

  const [collapse, setCollapse] = useState(false);
  const [activeChevron, setStateChevron] = useState('')

  const download = (orderId) => {
    //dispatch(toggleBlocking());
    ecommerceService.downloadSaleAsPDF(orderId);
  }

  function toggle() {
    setCollapse(!collapse);
    setStateChevron(activeChevron === '' ? 'open' : '')
  }

  return (
    <div className="wrapper_purchased_item ">

      <div className="head_purchased_item" onClick={toggle}>
        <div className="col_unicas">
          <span className={`ico_chevron ${activeChevron}`} >
            <i className="fas fa-chevron-down"></i>
          </span>
          <div className="cols">
            <h6 className="ttl_info">Fecha de pedido</h6>
            <p className="p_info">{props.date}</p>
          </div>
          <div className="cols">
            <h6 className="ttl_info">No. de pedido</h6>
            <p className="p_info">{props.orderNumber}</p>
          </div>
          <div className="cols">
            <h6 className="ttl_info">Total</h6>
            <p className="p_info">{countrySetting.currency}{(Number(props.total)).toLocaleString(countrySetting.cultureCode, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</p>
          </div>
          <div className="cols text-center">
            <h6 className="ttl_info">Status</h6>
            <p className="p_info"> <span className="paid">{props.status}</span> </p>
          </div>
        </div>
      </div>
      <Collapse
        isOpen={collapse}
        className="body_purchased_item"
      >
        <div className="row align-items-center">
          <div className="col-md-6 col-lg-8 info_item_shopping">
            {
              props.details.map(item => {
                return <PurchasedItemDetails {...item} />
              })
            }
          </div>

          <div className="col-md-6 col-lg-4">
            <div className="action_item_shopping">
              {props.status === "La orden ha sido pagada" && <p><button className="btn primary" onClick={() => download(props.orderId)} > <i className="fas fa-download"></i> Descargar</button></p>}
              <p ><small className="mt-4">Si tuviste algun problema para recibir tus folios, vuelve a intentarlo aquí.</small></p>
            </div>
          </div>
        </div>

      </Collapse>
    </div>
  )
}
export default PurchasedItem;