import { callApi } from './service'
import swal from "sweetalert"

export const branchOfficeService = {
    create,
    update,
    getAll,
    deleteBranchOffice
};

async function create(params) {
    return callApi('/backoffice/BranchOffice', 'POST', params);
}

async function update(params) {
    return callApi('/backoffice/BranchOffice', 'PUT', params);
}

async function getAll() {
    return callApi('/backoffice/BranchOffice', 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }

    });
}

async function deleteBranchOffice(params) {
    return callApi('/backoffice/BranchOffice', 'DELETE', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "La sucursal se desactivo correctamente", icon: "success" })
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al desactivar la sucursal", icon: "error" });
        }
    });
}
