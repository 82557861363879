import React, { useEffect, useState } from 'react';
import { useCompanyHoliday } from '../../../services/companyHoliday.service';

export function ListNotice(props) {
    const [CompanyHolidaysData, setCompanyHolidaysData] = useState([]);
    const handleDelete = (data) => {
        useCompanyHoliday.deleteCompanyHoliday(data).then((result) => { getCompanyHolidays(); });
    };

    useEffect(() => {
        getCompanyHolidays();
    }, [props.refresh]);

    async function getCompanyHolidays() {
        useCompanyHoliday.getCompanyHolidays().then((result) => {
            if (result) {
                setCompanyHolidaysData(result);
            }
        });
    }

    return (
        <div>
            <div className="mb-4 mt-4 divtop ">
                <div className="box_ttl_opt no_reverse">
                    <h3 className="mb-0">Lista de avisos</h3>
                    <div className="box_actions ">
                        <button type="button" className="btn secundary mr-2" onClick={props.onNewClicked}><i className="fas fa-plus"></i> Agregar aviso</button>
                    </div>
                </div>
            </div>
            <div className="table-responsive">
                <table className="wrapper_table table table-sm table-hover ">
                    <thead>
                        <tr>
                            <th className="text fnt_medium">Imagen</th>
                            <th className="text fnt_medium">Aviso</th>
                            <th className="text fnt_medium">Fecha de evento</th>
                            <th className="text fnt_medium">Desde</th>
                            <th className="text fnt_medium">Hasta</th>
                            <th className="text fnt_medium">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {CompanyHolidaysData.map(companyHoliday => (
                            <tr key={companyHoliday.companyHolidayId} >
                                <td>
                                    <img src={companyHoliday.imagePath} style={{ width: '60px' }} />
                                </td>
                                <td>{companyHoliday.description}</td>
                                <td>{companyHoliday.date}</td>
                                <td>{companyHoliday.startDate}</td>
                                <td>{companyHoliday.endDate}</td>
                                <td>
                                    <span className="icon_tables" onClick={() => props.onSelectCompanyHoliday(companyHoliday)} >
                                        <i className="fas fa-pen"></i>
                                    </span>
                                    <span className="icon_tables" onClick={() => handleDelete(companyHoliday)}>
                                        <i className="far fa-trash-alt"></i>
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </div>

    )
}