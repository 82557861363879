import React, { useState } from 'react';
import { EditAnswerType } from './editAnswerType';
import { SearchSurveyAnswerType } from './searchAnswerTypes';

export function AdminAnswerTypes() {
    const [showEdit, setShowEdit] = useState(false);
    const [selectedAnswerType, setSelectedAnswerType] = useState();
    const [refresh, setRefresh] = useState(0);

    const handleOnSelected = (survey) => {
        setShowEdit(true);
        setSelectedAnswerType({ SurveyAnswerTypeId: survey.surveyAnswerTypeId, Description: survey.description });
    };
    const handleOnNew = () => {
        setSelectedAnswerType(null);
        setShowEdit(true);
    };
    const handleOnCancelEdit = () => {
        setSelectedAnswerType(null);
        setShowEdit(false);
    }
    const handleOnSaveEdit = () => {
        setRefresh(prev => prev + 1);
        setShowEdit(false);
    }
    return (
        <div>
            <div>
                <button type="button" onClick={handleOnNew}>Add Answer Type</button>
            </div>
            <div>
                <SearchSurveyAnswerType onSelectAnswerType={handleOnSelected} refresh={refresh} />
            </div>
            <div>
                {showEdit ? <EditAnswerType currentAnswerType={selectedAnswerType} onCancelEdit={handleOnCancelEdit} onSave={handleOnSaveEdit} /> : null}
            </div>
        </div>
    );
}