import { callApi, callApiFileStorage } from './service'
import swal from "sweetalert"

export const messageService = {
    getMessage,
    getMessageById,
    createMessage,
    updateDraftMessage,
    updateMessage,
    deleteMessage
}

async function getMessage(type, orderBy, status) {
    return callApi('/backend/Message?type=' + type + '&orderBy=' + orderBy + '&status=' + status, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return []
        }
    });
}

async function getMessageById(id) {
    return callApi('/backend/Message/' + id, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return null
        }
    });
}

async function createMessage(params, files) {
    var data = new FormData();
    if (files)
        for (var i = 0; i < files.length; i++) {
            var f = files[i];
            data.append('files', f, f.name);
        }
    data.append("model", JSON.stringify(params))

    return callApiFileStorage('/backoffice/Message', 'POST', data, false)
        .then((result) => {
            if (result.responseCode === '00') {
                swal({ text: "Creación exitosa", icon: "success" })
                return result.data
            }
            else if(result.responseCode === '06'){
                swal({ text: "No puedes enviarte un mensaje a ti mismo", icon: "error" })
                return false
            }
            else {
                swal({ text: result.responseMessage, icon: "error" })
                return null
            }

        });
}

async function updateDraftMessage(params, files) {
    var data = new FormData();
    if (files)
        for (var i = 0; i < files.length; i++) {
            var f = files[i];
            data.append('files', f, f.name);
        }
    data.append("model", JSON.stringify(params))

    return callApiFileStorage('/backoffice/Message', 'PUT', data, false)
        .then((result) => {
            if (result.responseCode === '00') {
                swal({ text: "Actualizado correctamente", icon: "success" })
                return result.data
            }
            else {
                swal({ text: result.responseMessage, icon: "error" })
                return null
            }
        });
}

async function updateMessage(params) {
    var data = new FormData()
    data.append("model", JSON.stringify(params))

    return callApiFileStorage('/backend/Message', 'PUT', data, false).then((result) => {
        if (result.responseCode === '00') {
            return result.data
        }
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return null
        }
    });
}

async function deleteMessage(id) {
    return callApi('/backend/Message/' + id, 'DELETE').then((result) => {
        if (result.responseCode === '00')
            return result.data
        else {
            swal({ text: result.responseMessage, icon: "error" })
            return null
        }
    });
}