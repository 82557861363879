import React from 'react'
import { storageService } from '../../services/storage.service'

export function MessageAttachment(props) {

  const handleDelete = (event, data) => {
    storageService.deleteStorage({ EntityId: data.entityId, EntityTypeId: data.entityTypeId, Sequence: data.sequence })
      .then(() => {
        props.setFiles(
          props.files.filter((storage) => storage.sequence !== data.sequence)
        )
      })
    event.stopPropagation()
  };

  return (
    <span >
      {props.files != null && props.files.length > 0 &&
        <span className="files">
          {props.files.map(item => (
            <span>
              <a
                className="item_file"
                key={item.entityId + '-' + item.entityTypeId + '-' + item.sequence}
                download={item.fileName} href={`data:application/octet-stream;base64,${item.file}`}
              >

                <span className="ico_file"><i className="far fa-file-alt"></i></span>
                <small>{item.fileName}</small>
              </a>
              {props.isDelete != null && props.isDelete &&
                <span className="trans btn_delete" onClick={(event) => handleDelete(event, item)}>
                  <i className="far fa-trash-alt"></i>
                </span>
              }
            </span>
          ))}
        </span>
      }
    </span>
  );
}
