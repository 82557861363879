import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Collapse } from "reactstrap";

import "./menuAside.scss";
import IcoHome from "../../assets/imgs/iconos/ico_home.png";
import IcoProfile from "../../assets/imgs/iconos/ico_profile.png";
import IcoMessage from "../../assets/imgs/iconos/ico_message.png";
import IcoSchedule from "../../assets/imgs/iconos/ico_schedule.png";
import IcoPoll from "../../assets/imgs/iconos/ico_poll.png";
import IcoRewards from "../../assets/imgs/iconos/ico_rewards.png";
import IcoDiscount from "../../assets/imgs/iconos/ico_discount.png";
import IcoBusisness from "../../assets/imgs/iconos/ico_new_business.png";
import IcoAyuda from "../../assets/imgs/iconos/ico_ayuda.png";
import IcoPizarron from "../../assets/imgs/iconos/ico_pizarron.png";
import IcoCapacitacion from "../../assets/imgs/iconos/ico_capacitacion.png";
import IcoAdmin from "../../assets/imgs/iconos/ico_admin.png";
import IcoAudience from "../../assets/imgs/iconos/ico_audience.png";
import { useSelector } from "react-redux";
import { Legals } from "../legals/legals";


export function MenuAside({ activeMenu, closeAll }) {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [isOpen, setIsOpen] = useState(false);
  const toggleCollapse = () => setIsOpen(!isOpen);
  const [selected, setSelected] = useState(true);

  const MenuUsuario = () => {
    return (
      <div>
        <ul>
          <li>
            <NavLink className="menu_item" to="/home" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoHome} alt="Menu" />
              </span>
              <span>Muro</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="menu_item" to="/profile" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoProfile} alt="Menu" />
              </span>
              <span>Mi perfil</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="menu_item" to="/messages" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoMessage} alt="Menu" />
              </span>
              <span>Mensajes</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="menu_item" to="/diary" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoSchedule} alt="Menu" />
              </span>
              <span>Calendario</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="menu_item" to="/surveys" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoPoll} alt="Menu" />
              </span>
              <span>Tu opinión</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="menu_item" to="/our-company" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoBusisness} alt="Menu" />
              </span>
              <span>Nuestro mundo</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="menu_item" to="/rewards" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoRewards} alt="Menu" />
              </span>
              <span>Recompensas</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="menu_item" to="/course-view" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoCapacitacion} alt="Menu" />
              </span>
              <span>Talleres</span>
            </NavLink>
          </li>
          {/*<li>*/}
          {/*    <NavLink className="menu_item" to="/discount" onClick={closeAll}>*/}
          {/*        <span className="ico_imgs">*/}
          {/*            <img src={IcoDiscount} alt="Menu" />*/}
          {/*        </span>*/}
          {/*        <span>Descuentos Corporativos</span>*/}
          {/*    </NavLink>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*    <NavLink className="menu_item" to="/board" onClick={closeAll}>*/}
          {/*        <span className="ico_imgs">*/}
          {/*            <img src={IcoPizarron} alt="Menu" />*/}
          {/*        </span>*/}
          {/*        <span>Reconocimientos</span>*/}
          {/*    </NavLink>*/}
          {/*</li>*/}
         <li>
             <NavLink className="menu_item" to="/audience" onClick={closeAll}>
                 <span className="ico_imgs">
                     <img src={IcoAudience} alt="Menu" />
                 </span>
                 <span>Auditorio</span>
             </NavLink>
         </li>
          <li>
            <NavLink className="menu_item" to="/ecommerce" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoDiscount} alt="Menu" />
              </span>
              <span>Tienda en línea</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="menu_item" to="/help" onClick={closeAll}>
              <span className="ico_imgs">
                <img src={IcoAyuda} alt="Menu" />
              </span>
              <span>Ayuda</span>
            </NavLink>
          </li>
        </ul>
      </div>
    );
  };

  const MenuOperaciones = () => {
    return (
      <ul>
        <li>
          <div className="menu_item" onClick={toggleCollapse}>
            <span className="ico_imgs">
              <img src={IcoAdmin} alt="Menu" />
            </span>
            <span>
              Operaciones <i className="fas fa-caret-down"></i>
            </span>
          </div>
          <Collapse isOpen={isOpen}>
            <ul className="ul_collapse">
              <li>
                <NavLink
                  className="submenu_item"
                  to="/AdminCompany"
                  onClick={closeAll}
                >
                  Empresas
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="submenu_item"
                  to="/controlreward/1"
                  onClick={closeAll}
                >
                  Recompensas
                </NavLink>
              </li>
            </ul>
          </Collapse>
          <hr />
        </li>
      </ul>
    );
  };

  const MenuAdministrador = () => {
    return (
      <ul>
        <li>
          <div className="menu_item" onClick={toggleCollapse}>
            <span className="ico_imgs">
              <img src={IcoAdmin} alt="Menu" />
            </span>
            <span>
              Administrador <i className="fas fa-caret-down"></i>
            </span>
          </div>
          <Collapse isOpen={isOpen}>
            <ul className="ul_collapse">
              <li>
                <NavLink
                  className="submenu_item"
                  to="/admin-diary"
                  onClick={closeAll}
                >
                  Calendario
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="submenu_item"
                  to="/control"
                  onClick={closeAll}
                >
                  Control
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="submenu_item"
                  to="/course"
                  onClick={closeAll}
                >
                  Talleres
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="submenu_item"
                  to="/AdminSurvey"
                  onClick={closeAll}
                >
                  Tu opinión
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="submenu_item"
                  to="/CompanyPost"
                  onClick={closeAll}
                >
                  Nuestro mundo
                </NavLink>
              </li>
              {/*<li>*/}
              {/*    <NavLink className="submenu_item" to="/AdminBoard" onClick={closeAll}>Reconocimientos</NavLink>*/}
              {/*</li>*/}
              <li>
                <NavLink
                  className="submenu_item"
                  to="/product-filter"
                  onClick={closeAll}
                >
                  Recompensas
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="submenu_item"
                  to="/users"
                  onClick={closeAll}
                >
                  Usuarios
                </NavLink>
              </li>
            </ul>
          </Collapse>
          <hr />
          <MenuUsuario />
        </li>
      </ul>
    );
  };

  return (
    <aside className={`wrapper_menu_aside ${activeMenu}`}>
      <nav className="nav_menu_items">
        {
          {
            Operaciones: <MenuOperaciones />,
            "Recursos Humanos": <MenuAdministrador />,
            Usuario: <MenuUsuario />,
          }[roleName]
        }
      </nav>
      <Legals />
    </aside>
  );
}
