/**
 * Communication with the API
 * @param {*} url
 * @param {*} method
 * @param {*} body
 * @param {*} contentType
 */
import { toggleBlocking } from '../store/actions'
import store from '../store/configureStore'

export async function callApi(url, method, body, contentType = 'application/json') {
    var options = {
        method: method,
        headers: {
            'Content-Type': contentType,
            'Authorization': 'Bearer ' + localStorage["kuimby_token"]
        },
        body: JSON.stringify(body)
    };

    store.dispatch(toggleBlocking(true))
    return fetch(`${process.env.REACT_APP_API_URL}${url}`, options)
        .then(response => handleResponse(response, false))
        .catch(handleError);
}

/**
 * Communication with the API
 * @param {*} url
 * @param {*} method
 * @param {*} body
 * @param {*} contentType
 */

export async function callApiFileStorage(url, method, body, isFileResponse = true) {
    var options = {
        method: method,
        headers: {
            'Authorization': 'Bearer ' + localStorage["kuimby_token"]
        },
        body: body
    };

    store.dispatch(toggleBlocking(true))
    return fetch(`${process.env.REACT_APP_API_URL}${url}`, options)
        .then(response => handleResponse(response, isFileResponse))
        .catch(handleError);
}

/**
 * Communication with the API
 * @param {*} url
 * @param {*} method
 * @param {*} body
 * @param {*} contentType
 */

export async function callApiFile(url, method, body, contentType = 'application/json') {
    var options = {
        method: method,
        headers: {
            'Content-Type': contentType,
            'Authorization': 'Bearer ' + localStorage["kuimby_token"]
        },
        body: JSON.stringify(body)
    };

    store.dispatch(toggleBlocking(true))
    return fetch(`${process.env.REACT_APP_API_URL}${url}`, options)
        .then(response => handleResponse(response, true))
        .catch(handleError);
}


export function handleResponse(response, isFileResponse) {
    store.dispatch(toggleBlocking(false))
    if (!isFileResponse)
        return response.text()
            .then(text => {
                let data

                try {
                    data = text && JSON.parse(text);
                }
                catch (e) {
                    data = text
                }

                if (!response.ok) {
                    if (response.status === 401)
                        refreshToken();

                    const error = data === "" ? { msg: response.statusText } : data ?? { msg: response.statusText }
                    return Promise.reject(error);
                }

                return data;
            });
    else return response.blob();
}

export function handleError(error) {
    store.dispatch(toggleBlocking(false))
    console.log('error', error)
    if (error.message === "Failed to fetch")
        error.message = "El servicio no se encuentra disponible temporalmente";
    return Promise.reject(error);
}

async function refreshToken() {
    if (localStorage["kuimby_refresh_token"]) {
        if (localStorage["kuimby_refresh_token"] != "updating") {
            localStorage["kuimby_token"] = localStorage["kuimby_refresh_token"];
            localStorage["kuimby_refresh_token"] = "updating";
            callApi(`/auth/refreshtoken`, 'POST')
                .then((result) => {
                    if (result.responseCode === "00") {
                        localStorage['roleName'] = result.data.roleName;
                        localStorage['kuimby_token'] = result.data.token;
                        localStorage['kuimby_refresh_token'] = result.data.refreshToken;
                        localStorage['userId'] = result.data.userId;
                    } else {
                        localStorage['kuimby_refresh_token'] = "";
                        window.location.href = "/login";
                    }
                });
        }
    }
    else
        window.location.href = "/login";
}

//Sin bloquear la pantalla
export async function callApiNoBlocking(url, method, body, contentType = 'application/json') {
    var options = {
        method: method,
        headers: {
            'Content-Type': contentType,
            'Authorization': 'Bearer ' + localStorage["kuimby_token"]
        },
        body: JSON.stringify(body)
    };

    return fetch(`${process.env.REACT_APP_API_URL}${url}`, options)
        .then(response => handleResponseNoBlocking(response, false))
        .catch(handleErrorNoBlocking);
}

export function handleResponseNoBlocking(response, isFileResponse) {
    if (!isFileResponse)
        return response.text()
            .then(text => {
                let data

                try {
                    data = text && JSON.parse(text);
                }
                catch (e) {
                    data = text
                }

                if (!response.ok) {
                    if (response.status === 401)
                        refreshToken();

                    const error = data === "" ? { msg: response.statusText } : data ?? { msg: response.statusText }
                    return Promise.reject(error);
                }

                return data;
            });
    else return response.blob();
}

export function handleErrorNoBlocking(error) {
    console.log('error', error)
    if (error.message === "Failed to fetch")
        error.message = "El Servicio no se encuentra disponible";
    return Promise.reject(error);
}