import { callApi } from './service'

export const actionTypeService = {
    createActionType,
    getActionType,
    deleteActionType,
    updateActionType
};


async function createActionType(params) {
    return callApi('/backoffice/ActionType', 'POST', params);
}

async function updateActionType(params) {
    return callApi('/backoffice/ActionType', 'PUT', params);
}

async function getActionType() {
    return callApi('/backoffice/ActionType', 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }

    });
}

async function deleteActionType(params) {
    return callApi('/backoffice/ActionType', 'DELETE', params);
}