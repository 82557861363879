import React from 'react'

export default function AcknowledgementModal(props) {

  return (
    <div className="modal my_modal" tabindex="-1" role="dialog" style={{ display: "block" }}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text fnt_medium" id="exampleModalLongTitle">{props.isActive.title}</h4>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => props.setShow(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {props.top10.map((user, index) =>
              <div className="item_acknowledgment" key={index}>
                <div className="box_avatar_user">
                  <div className="avatar">
                    <img src={user.urlProfileImage} />
                  </div>
                </div>
                <div className="ml-2">
                        <h6 className="mb-0">{user.middleName} {user.lastName} {user.firstName}</h6>
                  <p className="mb-0 text-muted">
                    <small>{user.count} Reconocimientos</small>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}