import React, { useState } from 'react';
import { EditRewardProvider } from './editRewardProvider'

export function DetailsRewardProvider(props) {

    return (
        <div className="wrapper_details_reward padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt mb-0">
                    <div>
                        <h2 className="mb-0 text fnt_medium">Recompensas - Proveedores</h2>
                        <h6 className="mb-0 text-muted">{props.match.params.id != 0 ? "Editar proveedor" : "Nueva proveedor"}</h6>
                    </div>
                </div>
            </div>

            <EditRewardProvider rewardProviderId={props.match.params.id} />
        </div>
    );
}   
