import React, { useEffect, useState } from 'react';
import useInputForm from '../../components/CustonHooks/FormHook'
import { companyService } from '../../services/control/company.service';
import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';

export function EditCompany(props) {
    const dispatch = useDispatch();
    const onSave = () => {
        // debugger
        if (inputs.companyId && inputs.companyId > 0)
            companyService.edit(inputs);
        else
        {
            inputs.companyId = parseInt(companyRVOL.CompanyId);
            companyService.create(inputs);
            setInputs({ ...inputs, CompanyId: 0, CompanyName:"" });
            setIsRvol(false)
        }
    };

    
    const [isRvol, setIsRvol] = useState(false);
    const [companyRVOL, setCompanyRVOL] = useState({
        CompanyId : 0,
        CompanyName : ""
    });

    const [company, setCompany] = useState({
        companyId: 0,
        companyName: "",
        businessName: "",
        primeDomain: "",
        telephone1: "",
        telephone2: "",
        username: "",
        password: "",
        active: true
    });

    useEffect(() => {
        if (props.companyId !== "0") {

            companyService.getById(props.companyId).then((result) => {
                // debugger
                setCompany({
                    companyId: result.companyId,
                    companyName: result.companyName,
                    businessName: result.businessName,
                    primeDomain: result.primeDomain,
                    telephone1: result.telephone1,
                    telephone2: result.telephone2,
                    username: result.userName,
                    password: result.password,
                    active: result.active
                });
            });
        }
            else {
                companyService.getRVOL()
                    .then((result) => {
                        setCatalogs({ rvolCompany: result });
                    });
            }
    }, [props.companyId]);

    const handleCheckChange = (event) =>
    {
        setIsRvol(event.target.checked);

    }

    const handleSelectChange = (event) => {
        // debugger
        setCompanyRVOL({ CompanyId: event.target.value });
        var index = event.nativeEvent.target.selectedIndex;        
        setInputs({ ...inputs, companyName: event.nativeEvent.target[index].text });
    }

    const { handleSubmit, handleInputChange, handleInputFileChange, inputs, setInputs } = useInputForm(onSave, company);

    const [catalogs, setCatalogs] = useState({ rvolCompany: [] });

    return (
        <div className="wrapper_form_company card shadows p-4 ">
            <form onSubmit={handleSubmit} className="mb-5">
                <fieldset >
                    <legend className="text fnt_medium">{props.companyId !== 0 ? "Editar " : "Nueva "}empresa</legend>
                    { inputs.companyId === 0 &&
                    <div className="row" >
                    <div className="col-md-6 col-lg-4">
                        <div className="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                name="isRVOL"
                                id="isRVOL"
                                className="custom-control-input"
                                onChange={handleCheckChange}
                                checked={isRvol}
                                />
                            <label htmlFor="isRVOL" className="custom-control-label">¿Existe en Red Voucher Online?</label>
                        </div>
                    </div>
                    {isRvol && <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                            <label for="rvolCompany">Empresas Red Voucher Online</label>
                            <select className="form-control" id="rvolCompany" name="rvolCompany" onChange={handleSelectChange} value={companyRVOL.CompanyId}>
                                <option key="0" value="0" >Selecciona una opción</option>
                                {
                                    catalogs.rvolCompany.map(item => <option key={item.companyId} value={item.companyId}>{item.companyName}</option>)
                                }
                            </select>
                        </div>
                    </div>}
                </div>               

                    }


                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <input type="hidden" name="companyId" onChange={handleInputChange} value={inputs.companyId} />
                            <div className="form-group">
                                <label htmlFor="companyName">Nombre</label>
                                <input type="text" name="companyName" className="form-control" onChange={handleInputChange} value={inputs.companyName} placeholder="Identificador de la empresa" required />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="businessName">Nombre de la Empresa</label>
                                <input type="text" name="businessName" className="form-control" onChange={handleInputChange} value={inputs.businessName} placeholder="Nombre o razón social de la empresa" required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="primeDomain">Dominio</label>
                                <input type="text" name="primeDomain" className="form-control" onChange={handleInputChange} value={inputs.primeDomain} placeholder="Dominio corporativo" required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="telephone1">Teléfono oficina</label>
                                <input type="text" name="telephone1" className="form-control" onChange={handleInputChange} value={inputs.telephone1} placeholder="Teléfono principal" required />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="form-group">
                                <label htmlFor="telephone2">Teléfono alterno</label>
                                <input type="text" name="telephone2" className="form-control" onChange={handleInputChange} value={inputs.telephone2} placeholder="Teléfono alterno" required />
                            </div>
                        </div>
                    </div>
                    {props.companyId === "0" ?
                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="username">Usuario de R.H inicial</label>
                                    <input type="email" name="username" className="form-control" onChange={handleInputChange} value={inputs.username} placeholder="Correo corporativo" required />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="form-group">
                                    <label htmlFor="password">Contraseña</label>
                                    <input type="password" name="password" className="form-control" onChange={handleInputChange} value={inputs.password} placeholder="Contraseña" required />
                                </div>
                            </div>
                        </div>
                        : null}
                    <hr />
                    <div>
                        <Link className="btn secundary minimum ml-1 mr-1" to="/AdminCompany" >Cancelar</Link>
                        <button className="btn primary minimum" type="submit">Guardar</button>
                    </div>
                </fieldset>
            </form>
        </div>
    );
}   