import React from 'react'
import { useDropzone } from 'react-dropzone';
import { userProfileService } from '../../services/backoffice/userProfile.service'

import './users.scss'

import swal from "sweetalert"
import { useSelector } from 'react-redux';


export function BulkLoad() {
	const companyId = useSelector(state => state.userSettings.companyId)
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ multiple: false });

	const files = acceptedFiles.map(file => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
		</li>
	));

	const upload = () => {
		if (acceptedFiles.length === 0) {
			swal({ text: "Debes cargar un archivo para continuar", icon: "warning" });
		} else {
			userProfileService.uploadMigrationFile(companyId, acceptedFiles[0]);
		}
	}

	return (
		<section className="wrapper_users_details padd">
			<div className="mb-4 divtop">
				<div className="box_ttl_opt no_reverse mb-1 ">
					<div>
						<h2 className="mb-0 text fnt_medium">Usuario</h2>
						<h6 className="text-muted">Carga masiva</h6>
					</div>
					<div className="box_actions">
						<a className="btn secundary mr-2" href="https://storagervadmindev.blob.core.windows.net/ctnprimecontentmx/Common/TemplateUser.csv"><i className="far fa-file-excel"></i> Descargar template</a>
					</div>
				</div>
			</div>
			<div className="wrapper_dropzone">
				<div {...getRootProps({ className: 'dropzone shadows bradius' })}>
					<input {...getInputProps()} accept=".csv" />
					<div className="box_dropzone">

						<span><i className="fas fa-download"></i></span>
						<p >Arrastre y suelte un archivo .cvs aquí, o haga clic para seleccionar un archivo.</p>
					</div>
				</div>
				<aside className="mt-4">
					<h5 className="text fnt_medium">Archivo seleccionado:</h5>
					<ul>{files}</ul>
				</aside>
				<hr />
				<div className="text-right">
					<button onClick={upload} className="btn primary">Subir archivo</button>
				</div>
			</div>

		</section>
	)
}