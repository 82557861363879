export const cartHelper = {
    addItem,
    onChangeQuantity,
    deleteItem,
    deleteItemDetail,
    getItems,
    clean
}

async function addItem(product, provider) {
    let cart = JSON.parse(localStorage["cartKuimby"])
    let total = parseFloat(localStorage["cartTotalKuimby"]);
    let quantity = parseFloat(localStorage["cartQuantityKuimby"]);

    let item = {
        ProductId: product.productId,
        Name: product.name,
        Price: product.productPrice,
        Quantity: 1,
        Code: product.productCode,
        UrlImage: provider.urlLogoClrs,
        Descripcion: product.description,
        ProviderId: product.providerId,
        Total: product.productPrice
    }

    let foundProvider = cart.find(p => p.ProviderId == provider.providerId);
    if (foundProvider != undefined) {
        let isNew = true;

        let foundProduct = foundProvider.Items.find(p => p.ProductId == item.ProductId);
        if (foundProduct != undefined) {
            foundProduct.Quantity += item.Quantity;
            foundProduct.Total = foundProduct.Quantity * item.Price
            isNew = false;
        }

        if (isNew) foundProvider.Items.push(item)

        foundProvider.Total += item.Total;
        foundProvider.Quantity += item.Quantity;
    } else {
        let itemProvider =
        {
            ProviderId: provider.providerId,
            ProviderName: provider.name,
            Logo: provider.urlLogoClrs,
            Total: item.Price,
            Quantity: item.Quantity,
            Items: [item]
        }

        cart.push(itemProvider)
    }

    localStorage["cartTotalKuimby"] = total + item.Price
    localStorage["cartKuimby"] = JSON.stringify(cart)
    localStorage["cartQuantityKuimby"] = quantity + 1;
}

async function onChangeQuantity(providerId, productId, value) {
    let cart = JSON.parse(localStorage["cartKuimby"]);
    let total = parseFloat(localStorage["cartTotalKuimby"]);
    let quantity = parseFloat(localStorage["cartQuantityKuimby"]);

    let foundProvider = cart.find(p => p.ProviderId == providerId);
    if (foundProvider != undefined) {
        let foundProduct = foundProvider.Items.find(p => p.ProductId == productId);
        if (foundProduct != undefined) {

            foundProvider.Quantity -= foundProduct.Quantity;
            quantity -= foundProduct.Quantity;

            foundProvider.Total -= foundProduct.Total;
            total -= foundProduct.Total;

            foundProduct.Quantity = value;
            foundProduct.Total = foundProduct.Quantity * foundProduct.Price;

            foundProvider.Quantity += foundProduct.Quantity;
            quantity += foundProduct.Quantity;

            foundProvider.Total += foundProduct.Total;
            total += foundProduct.Total;
        }
    }

    localStorage["cartTotalKuimby"] = total;
    localStorage["cartKuimby"] = JSON.stringify(cart);
    localStorage["cartQuantityKuimby"] = quantity;
}

async function deleteItem(providerId) {
    let cart = JSON.parse(localStorage["cartKuimby"]);
    let total = parseFloat(localStorage["cartTotalKuimby"]);
    let quantity = parseFloat(localStorage["cartQuantityKuimby"]);

    let foundProvider = cart.find(p => p.ProviderId == providerId);
    if (foundProvider != undefined) {

        total -= foundProvider.Total;
        quantity -= foundProvider.Quantity;
        cart = cart.filter(p => p.ProviderId != providerId);
    }

    localStorage["cartTotalKuimby"] = total;
    localStorage["cartKuimby"] = JSON.stringify(cart);
    localStorage["cartQuantityKuimby"] = quantity;
}

async function deleteItemDetail(providerId, productId) {
    let cart = JSON.parse(localStorage["cartKuimby"]);
    let total = parseFloat(localStorage["cartTotalKuimby"]);
    let quantity = parseFloat(localStorage["cartQuantityKuimby"]);

    let foundProvider = cart.find(p => p.ProviderId == providerId);
    if (foundProvider != undefined) {
        let foundProduct = foundProvider.Items.find(p => p.ProductId == productId);
        if (foundProduct != undefined) {
            foundProvider.Quantity -= foundProduct.Quantity;
            quantity -= foundProduct.Quantity;

            foundProvider.Total -= foundProduct.Total;
            total -= foundProduct.Total;
            foundProvider.Items = foundProvider.Items.filter(p => p.ProductId != productId);

            if (foundProvider.Total == 0) cart = cart.filter(p => p.ProviderId != providerId);
        }
    }

    localStorage["cartTotalKuimby"] = total;
    localStorage["cartKuimby"] = JSON.stringify(cart);
    localStorage["cartQuantityKuimby"] = quantity;
}

function getItems() {
    let cart = JSON.parse(localStorage["cartKuimby"])

    let items = [];
    cart.map(provider => {
        provider.Items.map(itemtemp => {
            let item = {
                ProductId: itemtemp.ProductId,
                ProductPrice: itemtemp.Price,
                Cant: itemtemp.Quantity
            };
            items.push(item);
        })
    });

    return items;
}

async function clean() {
    localStorage["cartTotalKuimby"] = 0;
    localStorage["cartKuimby"] = "[]";
    localStorage["cartQuantityKuimby"] = 0;
}