import React from 'react';
import { Link } from 'react-router-dom'
import { SearchBoard } from './searchBoard';

import './boardAdmin.scss'

export function AdminBoard() {
  return (
    <section className="wrapper_survey_admin padd">
      <div className="mb-4 divtop">
        <div className="box_ttl_opt mb-0">
          <h2 className="mb-0 text fnt_medium">Reconocimiento</h2>
          <div className="box_actions">
            <Link className="link_data" to="/details-category">
              <button type="button" className="btn secundary">
                <i className="fas fa-plus"></i> Nueva categoría
              </button>
            </Link>
          </div>
        </div>
        <div className="box_ttl_opt">
          <h6 className="mb-0 text-muted">Administrador</h6>
        </div>
      </div>
      <div>
        <SearchBoard />
      </div>
    </section>
  );
}