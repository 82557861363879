import { callApi } from './service'

export const useAnswerConfig = {
    createAnswerConfig,
    updateAnswerConfig,
    deleteAnswerConfig,
    getAnswerConfigs
};
async function createAnswerConfig(params) {
    return callApi('/backoffice/SurveyAnswerConfig', 'POST', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function getAnswerConfigs(params) {
    return callApi('/backoffice/SurveyAnswerConfig/' + params, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function updateAnswerConfig(params) {
    return callApi('/backoffice/SurveyAnswerConfig', 'PUT', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}
async function deleteAnswerConfig(params) {
    return callApi('/backoffice/SurveyAnswerConfig', 'DELETE', params).then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}