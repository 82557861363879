import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { useFrontDiary } from '../../services/diary.service';

export function DiaryCalendar(props) {
    const [calendarDate, setCalendarDate] = useState({date:null});
    const localizer = momentLocalizer(moment)
    const [DiaryData, setDiaryData] = useState({ Diary: [] });
    const [CurrentRange, setCurrentRange] = useState({ startDate: null, endDate: null });
    const customEventPropGettet = (event, start, end, isSelected) => {
        if (event.diaryTypeId === 0) {
            return {
                className: 'event-event'
            }
        }
        else
            return {
                className: 'event-birthday'
            }
    }
    useEffect(() => {
        handleNavigate(new Date(), "month");
    }, []);
    useEffect(() => {
        getCompanyDiary();
    }, [props.refresh]);
    useEffect(() => {
        getCompanyDiary();
    }, [CurrentRange]);

    async function handleNavigate(date, view) {
        let start, end;

        setCalendarDate({ date: date });
        if (view === 'month') {
            start = moment(date).startOf('month').startOf('week').format('YYYY-MM-DD');
            end = moment(date).endOf('month').endOf('week').format('YYYY-MM-DD');
        }
        else if (view === 'week') {
            start = moment(date).startOf('isoWeek').format('YYYY-MM-DD');
            end = moment(date).endOf('isoWeek').format('YYYY-MM-DD');
        }
        else if (view === 'day') {
            start = moment(date).startOf('day').format('YYYY-MM-DD');
            end = moment(date).endOf('day').format('YYYY-MM-DD');
        }
        else if (view === 'agenda') {
            start = moment(date).startOf('day').format('YYYY-MM-DD');
            end = moment(date).endOf('day').add(1, 'month').format('YYYY-MM-DD');
        }
        var range = { startDate: start, endDate: end };
        setCurrentRange(range);
    }
    async function handleView(view) {
        let start, end;
        if (calendarDate.date) {
            if (view === 'month') {
                start = moment(calendarDate.date).startOf('month').startOf('week').format('YYYY-MM-DD');
                end = moment(calendarDate.date).endOf('month').endOf('week').format('YYYY-MM-DD');
            }
            else if (view === 'week') {
                start = moment(calendarDate.date).startOf('isoWeek').format('YYYY-MM-DD');
                end = moment(calendarDate.date).endOf('isoWeek').format('YYYY-MM-DD');
            }
            else if (view === 'day') {
                start = moment(calendarDate.date).startOf('day').format('YYYY-MM-DD');
                end = moment(calendarDate.date).endOf('day').format('YYYY-MM-DD');
            }
            else if (view === 'agenda') {
                start = moment(calendarDate.date).startOf('day').format('YYYY-MM-DD');
                end = moment(calendarDate.date).endOf('day').add(1, 'month').format('YYYY-MM-DD');
            }
            var range = { startDate: start, endDate: end };
            setCurrentRange(range);
        }
	}
    async function getCompanyDiary() {
        useFrontDiary.getFrontCompanyDiaryFiltered(CurrentRange.startDate, CurrentRange.endDate, null, true).then((result) => {
            if (result) {
                result.forEach(d => {
                    d.startDate = new Date(d.startDate)
                    d.endDate = new Date(d.endDate)
                });
                setDiaryData({ Diary: result });
            }
        });
    }
    const messages = {
        allDay: 'Todo el día',
        previous: '<',
        next: '>',
        today: 'Hoy',
        month: 'Mes',
        week: 'Semana',
        day: 'Día',
        agenda: 'Agenda',
        date: 'Fecha',
        time: 'Horario',
        event: 'Descripción del evento', 
        noEventsInRange: 'No hay eventos en este rango.',
    }
    return (
        <Calendar
            localizer={localizer}
            events={DiaryData.Diary}
            titleAccessor="name"
            allDayAccessor="allDay"
            startAccessor="startDate"
            endAccessor="endDate"
            onNavigate={handleNavigate}
            onView={handleView}
            eventPropGetter={customEventPropGettet}
            messages={messages}
        />
    );
}