import { callApi } from './service'

export const companyPostViewService = {
    InsertRead,
    getAll,
    DeleteReadPost,
    InsertLike,
    DeleteLikePost,
    getCompanyPostWithFiles,
    getCompanyPostFilter
};


async function InsertRead(params) {
    return callApi('/backend/InsertReadPost', 'POST', params);
}

async function DeleteReadPost(params) {
    return callApi('/backend/DeleteReadPost', 'POST', params);
}

async function InsertLike(params) {
    return callApi('/backend/InsertLikePost', 'POST', params).then((result) => handleRsponse(result));
}

async function DeleteLikePost(params) {
    return callApi('/backend/DeleteLikePost', 'POST', params).then((result) => handleRsponse(result));
}

async function getAll() {
    return callApi('/backend/CompanyPostDetail', 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            alert(result.responseMessage);
            return null;
        }

    });
}

async function getCompanyPostWithFiles(filterDate) {
    return callApi('/backend/CompanyPostDetail/WithFiles/' + filterDate, 'GET').then((result) => handleRsponse(result));
}

async function getCompanyPostFilter(postType) {
    let type = postType == 1 ? 0 : postType == 2 ? 1 : 0;
    return callApi('/backend/CompanyPost/GetFilter/' + type, 'GET').then((result) => handleRsponse(result));
}

function handleRsponse(result) {
    if (result.responseCode === '00')
        return result.data;
    else {
        alert(result.responseMessage);
        return null;
    }
}