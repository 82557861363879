import React, { useEffect, useState } from 'react';
import { useAnswerConfig } from '../../services/surveyAnswerConfig.service';

export function SearchAnswer(props) {
    console.log(props)
    const [answerData, setAnswersData] = useState([]);

    const handleDelete = (data) => {
        useAnswerConfig.deleteAnswerConfig(data).then((result) => { getAnswers(); });
    };

    const handleSelectAnswer = (data) => {
        if (props.onSelectAnswer)
            props.onSelectAnswer(data);
    };

    useEffect(() => {
        if (props.surveyQuestionId != "") getAnswers();
    }, [props.refresh]);

    async function getAnswers() {
        useAnswerConfig.getAnswerConfigs(props.surveyQuestionId).then((result) => {
            if (result) {
                setAnswersData(result);
            }
        });
    }

    return (
        <div className="table-responsive">
            <table className="wrapper_table table table-sm table-hover">
                <thead>
                    <tr>
                        <th>Orden	</th>
                        <th className="min_width">Respuesta</th>
                        <th>Estatus</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {answerData.map(answer => (
                        <tr key={answer.surveyAnswerConfigId}>
                            <td>{answer.answerOrder}</td>
                            <td><span className="link_data" onClick={() => handleSelectAnswer(answer)}>{answer.answer}</span></td>
                            <td>{answer.estatus}</td>
                            <td>
                                <span className="icon_tables" onClick={() => handleDelete(answer)}>
                                    <i className="far fa-trash-alt"></i>
                                </span>
                                <span className="icon_tables" onClick={() => handleSelectAnswer(answer)}>
                                    <i className="fas fa-pen"></i>
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    );
}