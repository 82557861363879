import React, { useState } from "react";
import BgConfen from "../../assets/imgs/banner_confer.jpg";
import _default from "react-bootstrap/esm/CardGroup";

export function BoxListAudience(props) {
  return (
    <div className=" content_card_audience">
      <h4 className="text-muted text fnt_medium mb-3">Disfruta de transmisiones</h4>
      <div className="box_list_audience">
        
        {/* <p className="mt-3">Actualmente tu organización no tiene contratado este módulo. Si requieres activarlo pídele a tu administrador de recursos humanos que se ponga en contacto con nosotros. </p> */}

        <div className="row">
          <div className="col-sm-6 col-lg-4 mb-4">
            <a
              className="item_ecommerce shadows card"
              href="https://demowebredaccess.comprarboletos.com/feria-congresos/enredhadas-61"
              target={_default}
            >
              <figure>
                <img src={BgConfen} alt="Auditorios autorizados en Kuimby Social" />
              </figure>
              <h5 className="mb-0 text fnt_bold text-uppercase">Red Access Online</h5>
              <p className="paragraph text-muted">Conferencias</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
