import React, { useState } from 'react'
import useInputForm from '../../components/CustonHooks/FormHook'
import { contactInfoService } from '../../services/helpContact.service'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import './help.scss'

import PictureHelp from '../../assets/imgs/picture_help.png'

export function Help() {
    const onSave = (event) => {
        contactInfoService.createContact(inputs);
        event.target.reset();
    };

    const [contactInfo, setContactInfo] = useState({
        name: "",
        businessName: "",
        email: "",
        message: ""
    });

    const { handleSubmit, handleInputChange, handleInputFileChange, inputs, setInputs } = useInputForm(onSave, contactInfo);
    return (
        <section className="wrapper_help padd">
            <div className="content_ttls">
                <div className="box_img_surveys">
                    <img src={PictureHelp} alt="Encuestas" />
                </div>
                <div className='minw'>
                    <h4 className="text fnt_medium ttl mb-3 text-white">Ayuda y soporte técnico</h4>
                    <p className="text fnt_medium">Te damos la bienvenida a nuestro Centro de Soporte Técnico, por favor identifica tu solicitud de servicio y sigue las instrucciones.</p>
                </div>
            </div>
            <div className="content_tabs_help">
                <h6 className="text-muted text fnt_medium mb-3">Uso de la Plataforma</h6>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className="row">
                        <div className="col-sm-5 col-lg-4 mb-4">
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">
                                        <span><i className="fas fa-tv"></i></span>
                                        <h5>¿Cómo funciona el sistema?</h5>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">
                                        <span><i className="fas fa-exchange-alt"></i></span>
                                        <h5>Canje de puntos</h5></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="four">
                                        <span><i className="fas fa-archive"></i></span>
                                        <h5>Atención de incidencias</h5></Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="col-sm-7 col-lg-8">
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className="content_info_help">
                                        <div className="ttl_info_help">
                                            <span><i className="fas fa-tv"></i></span>
                                            <h5>¿Cómo funciona el sistema?</h5>
                                        </div>
                                        <div>
                                            <h1>video</h1>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className="content_info_help">
                                        <div className="ttl_info_help">
                                            <span><i className="fas fa-exchange-alt"></i></span>
                                            <h5>Canje de puntos</h5>
                                        </div>
                                        <div>
                                            <ol>
                                                <li className="mb-4">
                                                    <h5 className="text fnt_medium">Acumula puntos por distintas acciones como:</h5>
                                                    <ul>
                                                        <li>Ingresa la sección encuestas y realiza las encuestas disponibles.</li>
                                                        <li>Ingresa a la sección capacitación y toma las capacitaciones asignadas por tu organización.</li>
                                                        <li>Realiza cualquier otra acción que tu organización haya determinado para sumar  y acumular puntos.</li>
                                                    </ul>
                                                </li>

                                                <li className="mb-4">
                                                    <h5 className="text fnt_medium">Canjea tus puntos por recompensas:</h5>
                                                    <ul>
                                                        <li>Ingresa a la sección recompensas y consulta tus puntos acumulados.</li>
                                                        <li>Consulta las recompensas disponibles y puntos requeridos para su canje.</li>
                                                        <li>Selecciona tu recompensa, canjea tus puntos, obtén tu cupón y disfruta de tu  beneficio.</li>
                                                    </ul>
                                                </li>

                                                <li className="mb-4">
                                                    <h5 className="text fnt_medium">Consulta tu historial:</h5>
                                                    <ul>
                                                        <li>Consulta tu historial de canje para visualizar tus recompensas descargadas    durante el mes.</li>
                                                        <li>Consulta tu estado de cuenta para visualizar tus puntos canjeados, activos y por vencer.</li>
                                                    </ul>

                                                </li>
                                            </ol>

                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="four">
                                    <div className="content_info_help">
                                        <div className="ttl_info_help">
                                            <span><i className="fas fa-archive"></i></span>
                                            <h5>Atención de incidencias</h5>
                                        </div>
                                        <div>
                                            <h5 className="text fnt_medium"> ¿Necesitas apoyo con la plataforma?</h5>
                                            <p>Por favor contáctanos a través del siguiente
                      formulario y un ejecutivo se pondrá en contacto contigo.</p>

                                            <h5 className="text fnt_medium"> Horario de atención:</h5>
                                            <p className="mb-0">9:00 a 18:00 hrs de Lunes a Jueves</p>
                                            <p>9:00 a 15:00 hrs Viernes</p>
                                            <hr />
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label for="name">Nombre completo</label>
                                                            <input type="text" className="form-control" id="name" name="name" placeholder="Nombre completo" onChange={handleInputChange} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label for="name">Empresa</label>
                                                            <input type="text" className="form-control" id="businessName" name="businessName" placeholder="Empresa" onChange={handleInputChange} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <label for="ejemplo_email_1">Correo electrónico</label>
                                                            <input type="email" className="form-control" id="email" name="email"
                                                                placeholder="Correo electrónico" onChange={handleInputChange} required />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <label for="mns">Mensaje</label>
                                                            <textarea className="form-control" id="message" name="message" rows="3" placeholder="Necesito notificar una incidencia…" onChange={handleInputChange} required></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 mt-4 text-right">
                                                        <button type="submit" className="btn primary"><i className="fas fa-paper-plane"></i> Enviar</button>
                                                    </div>
                                                </div>

                                            </form>

                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </section >
    )
}