import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { rewardProviderService } from '../../services/control/rewardProvider.service';

export function SearchRewardProvider(props) {
    const [rewardsData, setRewardsData] = useState({ rewards: [] });
    const handleDelete = (id) => {
        rewardProviderService.deleteProvider(id).then((result) => { getRewardProviders(); });
    };

    useEffect(() => {
        getRewardProviders();
    }, [props.refresh]);

    async function getRewardProviders() {
        rewardProviderService.get().then((result) => {
            if (result) {
                setRewardsData({ rewards: result });
            }
        });
    }

    return (
        <div className="table-responsive card shadows p-3">
            <table className="wrapper_table table table-hover table-sm">

                <thead>
                    <tr>
                        <th className="text fnt_medium">Id
					</th>
                        <th className="text fnt_medium min_width">Nombre
					</th>
                        <th className="text fnt_medium">Url logotipo
					</th>
                        <th className="text fnt_medium">Activo
					</th>
                    </tr>
                </thead>
                <tbody>
                    {rewardsData.rewards.map(reward => (
                        <tr key={reward.rewardProviderId}>
                            <td>
                                <Link className="link_data" to={"/details-rewardProvider/" + reward.rewardProviderId}>{reward.rewardProviderId}</Link>
                            </td>
                            <td>
                                <Link className="link_data" to={"/details-rewardProvider/" + reward.rewardProviderId}>{reward.businessName}</Link>
                            </td>
                            <td>
                                <Link className="link_data" to={"/details-rewardProvider/" + reward.rewardProviderId}>{reward.urlLogo}</Link>
                            </td>
                            <td>{reward.active ? "Activo" : "Inactivo"}</td>

                            <td>
                                <span className="icon_tables" onClick={() => handleDelete(reward.rewardProviderId)}>
                                    <i className="far fa-trash-alt"></i>
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    );
}