import React, { useEffect, useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import { companyPostViewService } from '../../services/compayPostView.service';
import { companyPostService } from '../../services/companyPost.service';
import { CompanyPostAttachment } from '../../components/companyPost/companyPostAttachment';
import { AsideOurCompany } from './asideOurCompany'
import { CompanyGallery } from './companyGallery'
import './ourCompany.scss';
import Moment from 'react-moment';
import 'moment/locale/es';

export function CompanyPostView() {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        };
    }

    const [activeAsideSurvey, setStateAsideSurvey] = useState('')

    const AsidePurchaseBox = () => {
        setStateAsideSurvey(activeAsideSurvey === '' ? ' is-show' : '')
    }


    const [post, setPost] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [sectionActive, setSectionActive] = useState('0');
    const [images, setImages] = useState([]);

    const [selectedPost, setSelectedPost] = useState({});
    const [selectedGallery, setSelectedGallery] = useState({});
    const [currentFilter, setcurrentFilter] = useState('undefined');

    async function getPost() {
        companyPostViewService.getCompanyPostWithFiles(currentFilter)
            .then((result) => {
                if (result && result != null && result.length > 0) {
                    setPost([]);
                    setPost(result);
                    if (selectedPost != null && selectedPost.companyPostId != null && selectedPost.companyPostId > 0) {
                        setCurrentPost(result)
                    } else {
                        handleSelectPost(result[0].data, result[0].files);
                    }

                }
            });
    }

    async function getGallery() {
        companyPostService.getAllFront(1, currentFilter)
            .then((result) => {
                if (result) {
                    if (result && result != null && result.length > 0) {
                        setGallery(result);
                        if (selectedGallery != null && selectedGallery.companyPostId != null && selectedGallery.companyPostId > 0) {
                            setCurrentGallery(result)
                        } else {
                            handleSelectGallery(result[0]);
                        }
                    }
                }
            });
    }

    const setCurrentPost = (dataItems) => {
        if (dataItems != null && dataItems.length > 0) {
            for (var i = 0; i < dataItems.length; i++) {
                var item = dataItems[i].data;
                if (selectedPost.companyPostId == item.companyPostId) {
                    setCurrentPostSelect(dataItems[i].data, dataItems[i].files)
                }
            }
        }
    };

    const setCurrentGallery = (dataItems) => {
        if (dataItems != null && dataItems.length > 0) {
            for (var i = 0; i < dataItems.length; i++) {
                var item = dataItems[i];
                if (selectedGallery.companyPostId == item.companyPostId) {
                    setCurrentGallerySelect(dataItems[i])
                }
            }
        }
    };

    useEffect(() => {
        if (activeTab == '1') {
            getPost();
        } else if (activeTab == '2') {
            getGallery();
        }
    }, [currentFilter, activeTab]);


    const setCurrentPostSelect = (item, files) => {
        setImages(files);
        setSelectedPost(item);
        setSectionActive("1");
    }

    const setCurrentGallerySelect = (item) => {
        setSectionActive("2");
        setSelectedGallery(item);
    }

    const handleSelectPost = (item, files) => {
        setImages(files);

        if (!item.isRead) {
            companyPostViewService.InsertRead({ CompanyPostId: item.companyPostId }).then((result) => {
                selectedPost.companyPostId = item.companyPostId;
                //setSelectedPost(item);
                getPost();
            });
        } else {
            setSelectedPost(item);
        }
        setSectionActive("1");
    }

    const handleSelectGallery = (item) => {
        if (!item.isRead) {
            companyPostViewService.InsertRead({ CompanyPostId: item.companyPostId }).then((result) => {
                selectedGallery.companyPostId = item.companyPostId;
                getGallery();
            });
        } else {
            setSelectedGallery(item);
        }
        setSectionActive("2");
    }

    const handleOnUnRead = () => {
        var id = sectionActive == "1" ? selectedPost.companyPostId : selectedGallery.companyPostId;
        companyPostViewService.DeleteReadPost({ CompanyPostId: id }).then((result) => {
            sectionActive == "1" ? getPost() : getGallery();
        });
    }

    const handleOnLike = () => {
        var id = sectionActive == "1" ? selectedPost.companyPostId : selectedGallery.companyPostId;
        companyPostViewService.InsertLike({ CompanyPostId: id }).then((result) => {
            if (result)
                sectionActive == "1" ? getPost() : getGallery();
        });
    }

    const handleOnUnLike = () => {
        var id = sectionActive == "1" ? selectedPost.companyPostId : selectedGallery.companyPostId;
        companyPostViewService.DeleteLikePost({ CompanyPostId: id }).then((result) => {
            if (result)
                sectionActive == "1" ? getPost() : getGallery();
        });
    }

    const [activeView, setStateView] = useState('')

    const BoxActiveView = () => {
        setStateView(activeView === '' ? ' is-show' : '')
    }

    const getPostDateFilter = (filterDate) => {
        var dateFilter = formatDate(filterDate);
        setcurrentFilter(dateFilter);
        selectedPost.companyPostId = 0;
        selectedGallery.companyPostId = 0;
        //setSectionActive("1");
        setImages([]);
        //getPost(dateFilter);
        //getGallery(dateFilter);
    }

    const formatDate = (date) => {
        let datestring = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
        return datestring;
    };

    return (
        <section className="wrapper_our_company">
            <div className="sec_list_post">
                <div className="mb-4 divtop">
                    <div className="box_ttl_opt">
                        <h3 className="mb-0 text fnt_medium">Nuestro mundo</h3>
                        <span className="btn_ellipsis" onClick={AsidePurchaseBox}><i className="fas fa-ellipsis-v"></i></span>
                    </div>
                </div>

                <Nav tabs className="ml-3 mr-3">
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                        >
                            <span className="text fnt_medium">Artículos</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                        >
                            <span className="text fnt_medium">Galería</span>
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="box_ttls_sec bradius article">
                            <div className="ico_box_sec">
                                <span><Moment locale="es" filter={(d) => d.substring(0, 1)} format="MMMM">{post[0] && post[0].data.publishDate}</Moment></span>
                            </div>
                            <div className="">
                                <h4 className="text fnt_medium mb-0"><Moment locale="es" format="MMMM YYYY">{post[0] && post[0].data.publishDate}</Moment></h4>
                                <p className="m-0"> <span>{post.length} artículo(s)</span> {/*<span>220 vistas</span>*/}</p>
                            </div>
                        </div>
                        <div className="content_list_post">
                            {post.map(item => (
                                <div
                                    className={`item_list_post  ${item.data.isRead ? 'is_read' : ''}`}
                                    key={item.data.companyPostId}
                                    onClick={() => handleSelectPost(item.data, item.files)}
                                >
                                    <div className="w-100" onClick={BoxActiveView}>
                                        {item.files != null && item.files.length > 0 ?
                                            <div className="box_avatar_user">
                                                <div className="avatar">
                                                    <img src={item.files[0].path} />
                                                </div>
                                            </div> : null}
                                        <div>
                                            <h6 className="text fnt_medium mb-0 mt-1">{item.data.title}</h6>
                                            <p className="m-0 date_post">
                                                <span><Moment locale="es" format=" DD [de] MMMM">{item && item.data.publishDate}</Moment></span>
                                            </p>
                                            <span className="views_post">{item && item.data.totalViews} vista(s)</span>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div className="box_ttls_sec bradius gallery">
                            <div className="ico_box_sec">
                                <span>A</span>
                            </div>
                            <div className="">
                                <h4 className="text fnt_medium mb-0">Álbumes</h4>
                            </div>
                        </div>
                        <div className="content_list_post">
                            {gallery.map(item => (
                                <div
                                    className='item_list_post'
                                    key={item.companyPostId}
                                    onClick={() => handleSelectGallery(item)}>
                                    <div className="w-100" onClick={BoxActiveView}>
                                        <div className="box_avatar_user">
                                            <div className="avatar">
                                                <img src={item.iconPath} alt="gallery" />
                                            </div>
                                        </div>
                                        <div>
                                            <h6 className="text fnt_medium mb-0 mt-1">{item.title}</h6>
                                            <span >{item && item.totalViews} vista(s)</span>
                                            <p className="m-0 date_post">
                                                <span><Moment locale="es" format=" DD [de] MMMM">{item.publishDate}</Moment></span>
                                            </p>
                                            <span className="views_post">{item.galleryImageCount} Elemento(s)</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </TabPane>
                </TabContent>
            </div>


            <div className={`sec_post ${activeView}`}>
                <span className="back_ico" onClick={BoxActiveView}>
                    <i className="fas fa-chevron-left"></i> Atrás
				</span>
                {{
                    "1":
                        <div className='content_post'>
                            <div className="content_info_post">
                                <h6 className="text-muted text-right"> <Moment locale="es" format=" DD [de] MMMM [de] YYYY">{selectedPost.publishDate}</Moment></h6>
                                <div className="info_post"  >

                                    {images != null && images.length > 0 ?
                                        <div className="box_avatar_user">
                                            <div className="avatar">
                                                <img src={images[0].path} />
                                            </div>
                                        </div>
                                        : null}

                                    <div>
                                        <h5 className="text fnt_medium mb-0 mt-1">{selectedPost.title}</h5>
                                        <h6 className="m-0 text-muted">
                                            Por <span>{selectedPost.writter}</span>
                                        </h6>
                                    </div>
                                </div>
                                <div>
                                    <div dangerouslySetInnerHTML={{ __html: selectedPost.text }} />
                                </div>
                            </div>
                            <div className="sec_controls_post">
                                <div className="content_options_post">
                                    <div className="box_files_post">
                                        <div className="likes">
                                            <span className="like_heart"><i className="fas fa-heart"></i></span>
                                            <span className="like_counter">{selectedPost.totalLikes}</span>
                                        </div>
                                        <CompanyPostAttachment CompanyPostId={selectedPost.companyPostId} IsDelete={false} />
                                    </div>
                                    <div className="box_actions_post">
                                        <div className="box_tag_read">
                                            <span className="tag_read" onClick={handleOnUnRead}>No leído</span>
                                        </div>
                                        <div className="box_btns_post">
                                            {selectedPost.like ?
                                                <span className="btn_action" onClick={handleOnUnLike}>
                                                    <i className="fas fa-heart"></i> Me gusta
                      </span>
                                                :
                                                <span className="btn_action" onClick={handleOnLike}>
                                                    <i className="far fa-heart"></i> Me gusta
                      </span>}

                                            {/*<span className="btn_action"><i className="far fa-comment-dots"></i> Comentar</span>*/}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>,
                    "2":
                        <div className="content_post">
                            <div className="content_info_post">
                                <h6 className="text-muted text-right">{selectedGallery.galleryImageCount} Elemento(s)</h6>
                                <div className="info_post"  >
                                    <div className="box_avatar_user">
                                        <div className="avatar">
                                            <img src={selectedGallery.iconPath} alt="gallery" />
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className="text fnt_medium mb-0 mt-1">{selectedGallery.title}</h5>
                                        <h6 className="m-0 text-muted">
                                            Por <span>{selectedGallery.writter}</span>
                                        </h6>
                                    </div>
                                </div>
                                <div>
                                    <CompanyGallery item={selectedGallery} />
                                </div>
                            </div>
                            {/*<div className="sec_controls_post">
                                <div className="content_options_post">
                                    <div className="box_files_post">
                                        <div className="likes">
                                            <span className="like_heart"><i className="fas fa-heart"></i></span>
                                            <span className="like_counter">{selectedGallery.totalLikes}</span>
                                        </div>
                                    </div>
                                    <div className="box_actions_post">
                                        <div className="box_tag_read">
                                            <span className="tag_read" onClick={handleOnUnRead}>No leído</span>
                                        </div>
                                        <div className="box_btns_post">
                                            {selectedGallery.like ?
                                                <span className="btn_action" onClick={handleOnUnLike}>
                                                    <i className="fas fa-heart"></i> Me gusta
                      </span>
                                                :
                                                <span className="btn_action" onClick={handleOnLike}>
                                                    <i className="far fa-heart"></i> Me gusta
                      </span>}

                                           <span className="btn_action"><i className="far fa-comment-dots"></i> Comentar</span>
                                        </div>

                                    </div>
                                </div>
                            </div >
                                */}
                        </div>
                }[sectionActive]
                }
            </div>

            <AsideOurCompany
                getPost={getPostDateFilter}
                postType={activeTab}
                activeAsideSurvey={activeAsideSurvey}
                onCloseClick={() => setStateAsideSurvey('')}
            />

        </section >
    );

}
