import React, { useState } from 'react';
import { ShowDiaryDates } from './diaryCalendar'
import { EditDiary } from './editDiary'
import './adminDiary.scss'

export function AdminDiary() {
    const [showEdit, setShowEdit] = useState(false);
    const [selectedDiary, setSelectedDiary] = useState();
    const [refresh, setRefresh] = useState(0);

    const handleOnNewDiary = () => {
        setSelectedDiary(null);
        setShowEdit(true);
    };
    const handleOnCancelEdit = () => {
        setSelectedDiary(null);
        setShowEdit(false);
    }
    const handleOnSaveEdit = (result) => {
        setRefresh(prev => prev + 1);
        if (result) {
            setSelectedDiary(null);
            setShowEdit(false);
        }
    }
    const handleOnEventSelected = (Diary) => {
        if (Diary.diaryTypeId != 1) {
            setSelectedDiary({ CompanyDiaryId: Diary.companyDiaryId, Name: Diary.name, DiaryTypeId: Diary.diaryTypeId, Location: Diary.location, StartDate: Diary.startDate, EndDate: Diary.endDate, Repeat: Diary.repeat, AllDay: Diary.allDay });
            setShowEdit(true);
        }
    }
    return (
        <section className="wrapper_diary_admin padd">
            <div style={{ display: showEdit ? "initial" : "none" }}> <EditDiary currentDiary={selectedDiary} onCancelEdit={handleOnCancelEdit} onSave={handleOnSaveEdit} /> </div>
            <div style={{ visibility: showEdit ? "hidden" : "visible" }}> <ShowDiaryDates onNewClicked={handleOnNewDiary} refresh={refresh} onEventSelected={handleOnEventSelected} /></div>
        </section>
    )
}