import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router
} from 'react-router-dom'
import { App } from './App';
import * as registerServiceWorker from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/theme/main.scss'
import { Provider } from 'react-redux';
import store from './store/configureStore';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router >
                <App />
            </Router >
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

registerServiceWorker.register({
    onUpdate: registration => {
        if (registration && registration.waiting) {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
        window.location.reload();
    }
});

