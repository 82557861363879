import React, { useState } from 'react';
import { SearchSurvey } from './searchSurvey';

import './surveyAdmin.scss'
import { Link } from 'react-router-dom';

export function AdminSurvey() {
    const [refresh, setRefresh] = useState(0);

    return (
        <section className="wrapper_survey_admin padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt no_reverse mb-0">
                    <div>
                        <h2 className="mb-0 text fnt_medium">Tu opinión</h2>
                        <h6 className="mb-0 text-muted">Administrador</h6>
                    </div>
                    <div className="box_actions">
                        <Link className="btn primary" to={"/details-survey/0"}><i className="fas fa-plus"></i> Agregar encuesta</Link>
                    </div>
                </div>
            </div>
            <div>
                <SearchSurvey refresh={refresh} />
            </div>

        </section>
    );
}