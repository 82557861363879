import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { backendService } from './../../services/backend.service'
import Moment from 'react-moment'
import 'moment/locale/es';
import { useSelector } from 'react-redux';
import { Preferences } from './preferences'

export function Pending(props) {
	const [pending, setPending] = useState([])
	const calendarStrings = useSelector(state => state.calendarStrings);
	const [profile, setProfileInfo] = useState()

	useEffect(() => {
		backendService.userProfileActivity(true)
			.then((data) => {
				setPending(data)
			});
	}, [])

	return (
		<aside className={`wrapper_pending trans ${props.asideShow}`}>
			<span className='closeAll' onClick={props.allClose}>
          <i className="far fa-window-close"></i>
			</span>
			<div className="card shadows">
				<h5 className="text fnt_medium"><i className="fas fa-bookmark"></i> Mis pendientes</h5>
				<hr className="mt-0" />
				<div className="content_peding">
					{pending.map(item =>
						<Link to={item.actionRoute} className="item_pending">
							<h6 className="text fnt_medium mb-0">{item.name}</h6>
							<h6 className="text fnt_book text-muted mb-0">{item.type}</h6>
							<h6 className="text fnt_book text-muted mb-0">
								<small>Vence <Moment calendar={calendarStrings}>{item.expirationDate}</Moment></small>
							</h6>
						</Link>
					)}
				</div>
				<Preferences preferences={ props.profile && props.profile.preferences} notification = {props.profile && props.profile.notification } />
			</div>
		</aside>
	)
}