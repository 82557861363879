import { callApi } from './service'
import swal from "sweetalert"

export const useSurvey = {
    createSurvey,
    updateSurvey,
    deleteSurvey,
    getSurveys,
    getSurveysById
};
async function createSurvey(params) {
    return callApi('/backoffice/Survey/Create', 'POST', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "Se guardo exitosamente", icon: "success" }).then(() => window.location.href = "/details-survey/" + result.data)
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al guardar", icon: "error" });
        }
    });
}
async function getSurveys() {
    return callApi('/backoffice/Survey', 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function getSurveysById(id) {
    return callApi('/backoffice/Survey/Get/' + id, 'GET').then((result) => {
        if (result.responseCode === '00')
            return result.data;
        else {
            alert(result.responseMessage);
            return null;
        }
    });
}

async function updateSurvey(params) {
    return callApi('/backoffice/Survey/PutSurvey', 'PUT', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "La encuesta se guardo exitosamente", icon: "success" })
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al guardar encuesta", icon: "error" });
        }
    });
}
async function deleteSurvey(params) {
    return callApi('/backoffice/Survey', 'DELETE', params).then((result) => {
        if (result.responseCode === '00') {
            swal({ text: "La encuesta se desactivo correctamente", icon: "success" })
            return result.data;
        } else {
            console.log(result.responseMessage);
            swal({ text: "Error al desactivar encuesta", icon: "error" });
            return null;
        }
    });
}