import React, { Fragment, useEffect, useState } from "react";
import useInputSingleForm from "../../components/CustonHooks/inputFormHook";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { GeneralUpload } from "../../components/Helpers/generalUpload";
import { SelectUsers } from "../../components/Helpers/selectUsers";
import { messageService } from "../../services/message.service";
import { EditorMessage } from "./editorMessage";
import { MessageAttachment } from "./messageAttachment";
import swal from "sweetalert";
import { useSelector } from "react-redux";

export default function ComposeNewMessage(props) {
    const roleName = useSelector((state) => state.userSettings.roleName);
    const [message] = useState({ subject: "", messageText: "" });
    const [userSelec, setUserSelec] = useState([]);
    const [localfiles, setLocalFiles] = useState([]);
    const [attachemts, setAttachments] = useState([]);

    useEffect(() => {
        if (props.draft.messageId) {
            setInputs({
                subject: props.draft.subject,
                messageText: props.draft.messageText,
            });
            messageService.getMessageById(props.draft.messageId).then((data) => {
                setAttachments(data.files);
            });
        } else {
            setInputs({ subject: "", messageText: "" });
        }
    }, [props.draft]);

    const onSave = (mode) => {
        if (mode === "draft") draftMessage(mode);
        else sendMessage();
    };

    const draftMessage = (mode) => {
        inputs.isDraft = true;
        if (props.draft.messageId) {
            inputs.messageId = props.draft.messageId;
            updateMessage();
        } else {
            swal({
                text: "Este mensaje se guardara sin destinatarios",
                icon: "info",
            }).then((value) => {
                if (value) {
                    setUserSelec([]);
                    creatMessage(localfiles, mode);
                }
            });
        }
    };

    const sendMessage = () => {
        if (userSelec.length < 1)
            swal({ text: "Debes elegir al menos un usuario", icon: "error" });

        if (inputs.subject === "")
            swal({ text: "El mensaje debe tener un asunto", icon: "error" });

        if (userSelec.length > 0 && inputs.subject !== "") {
            let files = localfiles;
            if (attachemts) {
                attachemts.forEach((attachemt) => {
                    let blob = b64toBlob(attachemt.file);
                    blob.name = attachemt.fileName;
                    files.push(blob);
                });
            }
            creatMessage(files);
            setLocalFiles([]);
        }
    };

    const b64toBlob = (
        b64Data,
        contentType = "application/octet-stream",
        sliceSize = 512
    ) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    const creatMessage = (files, mode) => {
        let usersTo = [];
        if (mode !== "draft")
            userSelec.forEach((user) => usersTo.push(user.id));
        let data = { message: inputs, usersTo: usersTo };
        messageService.createMessage(data, files).then((data) => {
            if (data != false) {
                props.setDraft({});
                goMessages();
            }
        });
    };

    const updateMessage = () =>
        messageService.updateDraftMessage(inputs, localfiles).then(() => {
            props.setDraft({});
            goMessages();
        });

    const goMessages = () => props.setIsActive(1);
    const {
        inputs,
        handleInputChange,
        handleSubmit,
        setInputs,
    } = useInputSingleForm(onSave, message);

    return (
        <Fragment>
            <div className="card shadows p-3">
                <form onSubmit={handleSubmit} id="message_form">
                    <h3 className="text fnt_medium mb-4"> Nuevo Mensaje </h3>
                    <SelectUsers selectUser={setUserSelec} />
                    <div className="mb-4 mt-4 row">
                        <div className="col-sm-5">
                            <div className="form-group">
                                <label> Asunto: </label>
                                <input
                                    type="text"
                                    name="subject"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    value={inputs.subject}
                                    placeholder="Asunto"
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-sm-7">
                            <div className="row">
                                <div className="col-sm-6">
                                    <GeneralUpload
                                        TitleLabel={"Adjuntos:"}
                                        IsMultipe={true}
                                        handleOnChangeFiles={setLocalFiles}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <span className="files">
                                        {props.draft.messageId && (
                                            <MessageAttachment
                                                files={attachemts}
                                                setFiles={setAttachments}
                                                isDelete={true}
                                            />
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <EditorMessage
                        content={inputs.messageText}
                        name="messageText"
                        handleInputChange={handleInputChange}
                    />
                </form>
                <div className="mt-4 mb-4 text-right">
                    {roleName == "Recursos Humanos" && (
                        <button
                            className="btn secundary minimum mr-2"
                            onClick={() => onSave("draft")}
                        >
                            Guardar
                        </button>
                    )}
                    <button
                        className="btn secundary minimum mr-2"
                        onClick={() => props.setIsActive(props.draft.messageId ? 4 : 1)}
                    >
                        {props.draft.messageId ? "Regresar" : "Cancelar"}
                    </button>
                    <button
                        className="btn primary minimum"
                        onClick={() => onSave("send")}
                    >
                        Enviar
          </button>
                </div>
            </div>
        </Fragment>
    );
}
