import React, { useState } from 'react'
import './audience.scss'
import Moment from 'react-moment'
import 'moment/locale/es'
import { BoxListAudience } from './boxListAudience'
import PictureHelp from '../../assets/imgs/picture_audience.png'

export function Audience(props) {
  const [activeAsideSurvey, setStateAsideSurvey] = useState('')

  const AsidePurchaseBox = () => {
    setStateAsideSurvey(activeAsideSurvey === '' ? ' is-show' : '')
  }

  return (
    <section className="wrapper_surveys wrapper_audience padd">
      <div className="content_surveys ">
        <div className="row align-items-center mb-4">
          <div className="col"><h2 className="mb-0 text fnt_medium ">Auditorio</h2></div>
          <div className="col text-muted box_more_survey">
            <span className="d-none d-sm-block"><Moment locale="es" format="DD [de] MMMM  YYYY">{Date()}</Moment></span>
            <span className="btn_ellipsis" onClick={AsidePurchaseBox}><i className="fas fa-ellipsis-v"></i></span>
          </div>
        </div>
        <div className="content_ttls">
          <div className='minw'>
            <h4 className="text fnt_medium ttl mb-3 text-white">Bienvenido al auditorio corporativo</h4>
            <p className="text fnt_medium">Este es nuestro auditorio virtual en donde compartiremos contigo transmisiones en vivo de nuestros eventos y conferencias para que puedas tomarlos en línea.</p>
          </div>
          <div className="box_img_surveys">
            <img src={PictureHelp} alt="Encuestas" />
          </div>
        </div>

       <BoxListAudience />

        <p className="text-muted line_height">
          <small>La entrada a estos cursos está condicionada a los derechos de admisión de la compañía, así como los servicios de interacción de las transmisiones. </small>
       </p>
      </div>

      <aside className={`aside_lateral trans ${activeAsideSurvey}`}>
        <span className="btn_ellipsis_close" onClick={AsidePurchaseBox}><i className="far fa-window-close"></i></span>
        <div className="text-center">
          <h3 className="text fnt_medium mb-1">Recompensas</h3>
          {/* <h5 className="text-muted">Nombre</h5> */}

          {/* <div className="box_avatar_user">
            <span className="avatar">
              <img src={profile && profile.urlProfileImage} alt="NameUser" />
            </span>
          </div> */}

          <h6 className="text fnt_medium mb-1">Ganar en alguna categoría</h6>
          <br>
          </br>
          <div>
            <h3 className="text fnt_medium c_green">120 puntos</h3>
          </div>
        </div >
        <div className="prev_surveys">
          <h6 className="text fnt_medium mb-4 text-muted">Streamings presenciados</h6>
          <div className="item_prev_surveys">
            <span><i className="fas fa-star"></i></span>
            <div>
              <h6 className="mb-0 text fnt_medium">Kickoff Red Voucher</h6>
              <p><small className="text-muted">15 de Enero 2020</small></p>
            </div>
          </div>
        </div>
      </aside>
    </section>
  )
}