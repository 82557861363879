import React, { useState, useEffect } from "react";
import "./board.scss";
import { backendService } from "../../services/backend.service";
import { useAcknowledgment } from "../../services/acknowledgment.service";
import Moment from "react-moment";
import "moment/locale/es";
import AcknowledgementModal from "./acknowledgementModal";
import FormAcknowledgement from "./formAcknowledgement";
import AcknowledgementRanking from "./acknowledgementRanking";
import TextBoard from "./boardText";

export function Board(props) {
  const [activeAsideSurvey, setStateAsideSurvey] = useState("");
  const [profile, setProfileInfo] = useState();
  const [showAcknowledgementModal, setShowAcknowledgementModal] = useState(
    false
  );
  const [isActive, setIsActive] = useState();
  const [top10, setTop10] = useState([]);
  const [acknowledgmentUserProfile, setAcknowledgmentUserProfile] = useState(
    []
  );

  useEffect(() => {
    getUserProfile();
    // debugger
    useAcknowledgment
      .getAcknowledgmentUserModule()
      .then((data) => setAcknowledgmentUserProfile(data));
  }, [props.refresh]);

  useEffect(() => {
    getRanking();
  }, [isActive]);

  const getRanking = () => {
    if (isActive)
      useAcknowledgment
        .getUserProfileTop10(isActive.acknowledgmentTypeId)
        .then((data) => setTop10(data));
  };

  const getUserProfile = () => {
    backendService.userProfile().then((data) => {
      // debugger
      setProfileInfo(data);
    });
  };

  const AsidePurchaseBox = () => {
    setStateAsideSurvey(activeAsideSurvey === "" ? " is-show" : "");
  };

  const [isActiveBoard, setActiveBoard] = useState("");
  const MyFunBoard = () => {
    setActiveBoard(isActiveBoard === "" ? "is-show" : "");
  };

  const [activeForm, setActiveForm] = useState("");
  const MyFormBoard = () => {
    setActiveForm(activeForm === "" ? "is-show" : "");
  };

  return (
    <section className="wrapper_surveys wrapper_board padd">
      <div className="content_surveys ">
        <div className="row align-items-center mb-4">
          <div className="col">
            <h2 className="mb-0 text fnt_medium ">Reconocimiento</h2>
          </div>
          <div className="col text-muted box_more_survey">
            <span className="d-none d-sm-block">
              <Moment locale="es" format="DD [de] MMMM  YYYY">
                {Date()}
              </Moment>
            </span>
            <span className="btn_ellipsis" onClick={AsidePurchaseBox}>
              <i className="fas fa-ellipsis-v"></i>
            </span>
          </div>
        </div>
        <div className="row">
          <TextBoard setIsActive={setIsActive} MyFunBoard={MyFunBoard} />
          <div
            className={`col-sm-7 col-lg-8 mb-4 box_content_board ${isActiveBoard}`}
          >
            <div className="mb-3 back_ico">
              <span onClick={MyFunBoard}>
                <i className="fas fa-chevron-left"></i> Atrás
              </span>
            </div>

            <div className="box_ranking_new">
              <span className="link_ranking_new cursor" onClick={MyFormBoard}>
                <i className="fas fa-plus"></i> Agregar reconocimiento
              </span>
            </div>

            {isActive && (
              <AcknowledgementRanking
                setShowAcknowledgementModal={setShowAcknowledgementModal}
                isActive={isActive}
                top10={top10}
              />
            )}
            {!isActive && (
              <div className="box_ranking">
                <h5 className="text fnt_medium">Ranking</h5>
                <div className="card shadows">
                  <h6 className="text-muted text-center mt-2">
                    <i className="fas fa-long-arrow-alt-left"></i> Selecciona
                    una categoría
                  </h6>
                </div>
              </div>
            )}

            {showAcknowledgementModal && (
              <AcknowledgementModal
                setShow={setShowAcknowledgementModal}
                isActive={isActive}
                top10={top10}
              />
            )}

            <div className={`box_recognition ${activeForm}`}>
              <FormAcknowledgement updateRanking={getRanking} />
            </div>
          </div>
        </div>
      </div>
      <aside className={`aside_lateral trans ${activeAsideSurvey}`}>
        <span className="btn_ellipsis_close" onClick={AsidePurchaseBox}>
          <i className="far fa-window-close"></i>
        </span>
        <div className="text-center">
          <h3 className="text fnt_medium mb-1">Recompensas</h3>
          {
            <h5 className="text-muted">
              {profile && profile.middleName} {profile && profile.lastName}{" "}
              {profile && profile.firstName}
            </h5>
          }
          {/* <h5 className="text-muted">{profile && profile.firstName} {profile && profile.middleName} {profile && profile.lastName}</h5> */}
          <div className="box_avatar_user">
            <span className="avatar">
              <img src={profile && profile.urlProfileImage} alt="NameUser" />
            </span>
          </div>

          <div>
            <h3 className="text fnt_medium c_green">120 puntos</h3>
          </div>
        </div>
        {acknowledgmentUserProfile.length > 0 && (
          <div className="prev_surveys">
            <h6 className="text fnt_medium text-center text-muted">
              Mis reconocimientos
            </h6>
            <div className="box_item_surv">
              {acknowledgmentUserProfile.map((acknowledgment, index) => (
                <div key={index} className="item_prev_surveys">
                  {acknowledgment.logo ? (
                    <img src={acknowledgment.logo} width="25" height="25" />
                  ) : (
                    <span>
                      <i className="fas fa-star"></i>
                    </span>
                  )}
                  <div className="pl-1">
                    <h6 className="mb-0 text fnt_medium">
                      {acknowledgment.title}
                    </h6>
                    <p>
                      <small className="text-muted">
                        {acknowledgment.count} reconocimientos
                      </small>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </aside>
    </section>
  );
}
