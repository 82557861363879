import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { companyPostService } from '../../services/companyPost.service';
import Moment from 'react-moment'

const SearchGalleries = forwardRef((props, ref) => {

    const [dataGrid, setDataGrid] = useState([]);
    const handleDelete = (event, data) => {
        companyPostService.deletet(data).then((result) => { getAll(); });
        event.stopPropagation();
    };

    async function getAll() {
        companyPostService.getAll(1)
            .then((result) => {
                if (result) {
                    setDataGrid(result);
                }
            });
    }

    useEffect(() => {
        getAll();
    }, []);

    const refreshDataGrid = () => {
        getAll();
    }

    useImperativeHandle(ref, () => ({
        refreshDataGrid: refreshDataGrid  
    }));

    return (
        <div className="table-responsive">
            <table className="wrapper_table table table-sm table-hover">
                <thead>
                    <tr>
                        <th className="text fnt_medium min_width">Título de álbum</th>
                        <th className="text fnt_medium min_width">Fecha de publicación</th>
                        <th className="text fnt_medium text-center">Elementos</th>
                        <th className="text fnt_medium text-center">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {dataGrid.map(item => (
                        <tr key={item.companyPostId}>
                            <td >
                                <span className="link_data" onClick={() => props.handleRowSelect(item)}>
                                    <img src={item.iconPath} style={{ width: '40px', height: '40px', marginRight: '10px', borderRadius: '50%' }} /> 
                                    {item.title}
                                </span>
                            </td>
                            <td ><Moment format="DD/MM/YYYY">{item.publishDate}</Moment></td>
                            <td className="text-center">{item.galleryImageCount} Elemento(s)</td>
                            {item.active ?
                                <td className="text-center">
                                    <span className="icon_tables" onClick={(event) => handleDelete(event, item)}>
                                        <i className="far fa-trash-alt"></i>
                                    </span>
                                </td> : null}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

});

export default SearchGalleries;
