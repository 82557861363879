import React, { useState, useEffect } from 'react';
import Slider from "react-slick"

import './sliderDiary.scss'

import { useFrontDiary } from '../../services/diary.service';

export function DiarySlider() {
    const [sliderItems, setSliderItems] = useState([]);

    useEffect(() => {
        getEvents();
    }, []);

    async function getEvents() {
        useFrontDiary.getCompanyHolidayFront(0).then((result) => {
            if (result) {
                var temp = [];
                result.map((ch) => {
                        temp.push({
                            key: ch.companyHolidayId.toString(),
                            file: ch.imagePath,
                            description: ch.description
                        });
                });
                setSliderItems(temp);
            }
        });
    }


    const settings = {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        adaptiveHeight: true
    };
    return (
        <div className="wrapper_slide_home">
            {sliderItems && sliderItems.length > 0?
                <Slider {...settings}>
                    {
                        sliderItems.map(item =>
                            <div key={item.key}>
                                <img className="item_img_slider" src={item.file} alt={item.description} />
                            </div>)
                    }
                </Slider>
                : <img className="item_img_slider" src="/static/media/R_C.796d6968.png" alt="Red Companies" style={{ width: "50%", marginLeft: "auto", marginRight: "auto", display:"block" } } />}
        </div>
    )
}
