import React from 'react'
import './providerVideo.scss'

const ProviderVideo = ({ urlVideo }) => {
  return (
    <div className="box_iframe_video">
          <iframe width="560" height="315" src={urlVideo} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  )
}

export default ProviderVideo;