import { callApi, callApiFileStorage } from '../service'
import swal from "sweetalert"

export const companyCustomizeService = {
    getById,
    getByCompanyId,
    create,
    edit
};

async function getById(id) {
    return callApi('/BackOffice/CompanyCustomize/' + id, 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                return {};
            }
        });
}

async function getByCompanyId(companyId) {
    return callApi('/BackOffice/CompanyCustomize/GetByCompanyId/' + companyId, 'GET')
        .then((result) => {
            if (result.responseCode === "00") {
                return result.data;
            } else {
                return {};
            }
        });
}

async function create(data) {
    const formData = new FormData();
    formData.append("companyCustomizeId", data.CompanyCustomizeId);
    formData.append("companyId", data.CompanyId);
    formData.append("customizeValues", data.CustomizeValues);
    formData.append("companyLogoFile", data.CompanyLogoFile);
    
    return callApiFileStorage('/BackOffice/CompanyCustomize', 'POST', formData, false)
        .then((result) => {
            if (result.responseCode === "00") {
                return swal({ text: "Registro guardado exitosamente", icon: "success" })
                    .then(() => {
                        return true;;
                    });
            }else {
                swal({ text: result.responseMessage, icon: "error" });
            }
        });
}

async function edit(data) {
    const formData = new FormData();
    formData.append("companyCustomizeId", data.CompanyCustomizeId);
    formData.append("companyId", data.CompanyId);
    formData.append("customizeValues", data.CustomizeValues);
    formData.append("companyLogoFile", data.CompanyLogoFile);
    return callApiFileStorage('/BackOffice/CompanyCustomize', 'PUT', formData,false)
        .then((result) => {
            if (result.responseCode === "00") {
                return swal({ text: "Registro guardado exitosamente", icon: "success" })
                    .then((value) => {
                        return true;
                    });
            } else {
                swal({ text: result.responseMessage, icon: "error" });
            }
        });
}


