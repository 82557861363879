import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import './controlReward.scss'
import classnames from 'classnames';
import { AdminReward } from './adminReward'
import { AdminRewardCategory } from './adminRewardCategory'
import { AdminRewardProvider } from './adminRewardProvider'

export function ControlReward(props) {
	const [activeTab, setActiveTab] = useState(props.match.params.tap);

	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	}

	return (
		<section className="wrapper_control padd">
			<div className="mb-4 divtop ">
				<div className="box_ttl_opt no_reverse">
					<h2 className="mb-0 text fnt_medium">Control Recompensas</h2>
				</div>
			</div>

			<Nav tabs>
				<NavItem>
					<NavLink
						className={classnames({ active: activeTab === '1' })}
						onClick={() => { toggle('1'); }}
					>
						Productos
          </NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={classnames({ active: activeTab === '2' })}
						onClick={() => { toggle('2'); }}
					>
						Proveedores
          </NavLink>
				</NavItem><NavItem>
					<NavLink
						className={classnames({ active: activeTab === '3' })}
						onClick={() => { toggle('3'); }}
					>
						Categorías
          </NavLink>
				</NavItem>
			</Nav>

			<TabContent activeTab={activeTab}>
				<TabPane tabId="1">
					<div className="card_tabs">
						<AdminReward />
					</div>
				</TabPane>
			</TabContent>
			<TabContent activeTab={activeTab}>
				<TabPane tabId="2">
					<div className="card_tabs">
						<AdminRewardProvider />
					</div>
				</TabPane>
			</TabContent>
			<TabContent activeTab={activeTab}>
				<TabPane tabId="3">
					<div className="card_tabs">
						<AdminRewardCategory />
					</div>
				</TabPane>
			</TabContent>
		</section>
	)
}